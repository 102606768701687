import { useContext } from "react";
import { StateContext } from "../data/stateContext";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { FiLogOut } from "react-icons/fi";

export const Logout = styled.div`
    font-size: 1.5rem;
    position:absolute;
    bottom:3rem;
    /* left:2rem; */
    text-decoration: underline;
    cursor:pointer;
    &:hover{
        color:black;
    }
    @media (max-width: 768px) {
        position:absolute;
  }
    
`

export default (props) => {
    const {saveJwt,setUser} = useContext(StateContext)
    const navigate = useNavigate();

    const handleClick = () => {
        saveJwt(null)
        setUser(null)
        navigate('/login')  

    }   

    return (
        <Logout onClick={handleClick} style={{...props.style}}>
            <FiLogOut/>Logout
        </Logout>
    )
}