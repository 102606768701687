import {useContext} from 'react';
import styled from 'styled-components';
import { BsGlobe2 } from "react-icons/bs";
import colors from '../data/colors';
import { StateContext } from '../data/stateContext';
const Switcher = styled.div`
    position:absolute;
    ${props => props.pos.map(item => item)}
    /* bottom:3rem;
    left:3rem; */
    width:3rem;
    height:3rem;
    /* perspective:80rem; */
    cursor:pointer;
    transform:scale(1) ;
    &:active {
        transform:scale(0.95) ;
    }
    #relative {
        
        position:relative;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        #logos {
                position:relative ;
                width:100%;
                height:100%;
                
            }
            #strings {
                position:fixed;
                top:0;
                left:3.3rem;
                p {
                    transition: all 0.15s linear;
                    line-height:1.1;
                    color: ${colors[2]};
                    transform:scale(1);
                    transform-origin: left center ;
                    font-size:1.2rem ;
                    
                    &.active {
                        color: ${props => colors[props.color]};
                        transform:scale(1);
                    }
                    user-select:none ;
                }
            }

    }


`

function LanguageSwitcher({color = 1,pos = ['top:3rem;','left:3rem;']}) {

    const {locale, setLocale } = useContext(StateContext)
    return (
        <Switcher pos={pos} color={color} onClick={()=> setLocale(locale === "en" ? "da-DK" : "en")}>
            <div id="relative">
                <BsGlobe2 size={25} color={colors[color]} />
                <div id="strings">
                    <p className={locale === "en" ? 'active' : ''}>English</p>
                    <p className={locale === "da-DK" ? 'active' : ''}>Dansk</p>
                </div>

            </div>
        </Switcher>
    );
}

export default LanguageSwitcher;