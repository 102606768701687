import styled from "styled-components"
import colors from "../data/colors"
import { BiError } from "react-icons/bi";

const Error = styled.div`
    position: fixed;
    top:50%;left:50%;
    transform:translate(-50%,-50%);
    width:33vw;
    height:20vh;
    background-color:${colors[3]};
    border-radius:15px;
    z-index:100;

    & > div {
        position:relative;
        height:100%;width:100%;
        display:flex;
        justify-content:center;
        align-items:center;
        
        #button {
            display:flex;
            justify-content:center;
            align-items:center;
            position:absolute;
            bottom:2rem;
            left:50%;
            transform:translate(-50%,0);
            width:5rem;
            height:1rem;
            & > div {
                background-color: ${colors[2]};
                padding:0.5rem 1rem;
                margin:0.5rem;
                cursor:pointer;

            }
        }
    }
`

export function ErrorBox({message,prompt,cb}) {

    const buttonPrompts = prompt.map((str,index)=> 
        <div key={index} onClick={cb[index]}><p>{str}</p></div> 
    )
    return (            
    <Error>
        <div>
            <div id="icon"><BiError size={30}/></div>
            <h1>{message}</h1>
            <div id="button">
                {buttonPrompts}
            </div>
        </div>
    </Error> );
}