import styled from "styled-components"
import colors from '../data/colors';

const TextBox = styled.div`
  /* position: absolute; */
  top:1rem;
  left:0;
  max-width:40rem;
  width:calc(100% - 7rem);
  /* background-color: red; */
  height: 55vh;
  margin: 0 auto;
  transform:translate(0,25%);
  background-color:${colors[1]} ;
  border-radius:15px;
  box-shadow:2px 2px 9px ${colors[2]};
  padding:3rem;
  color:white;

  @media (max-width: 768px) {
    transform:translate(0,15%);
    /* padding: 8rem 0; */
        /* min-height:calc(100vh - 8rem); */
  }

  .login {
    width:80%;
    margin: 1rem auto;
  }

  #success {    
    width:80%;
    margin: 1rem auto;
    h1 {
      font-size: 2rem;
      margin-bottom: 1em;
    }
    a, a:visited {
      color:white;
    }
  }
`

export default TextBox