import React, {useEffect, useState} from 'react';
import { ResponsiveBar } from '@nivo/bar'

const Tooltip = (props) => {
    return (
        <div style={{padding:"1rem",width:"20rem",backgroundColor:"white", color:"black"}}>   
            <span style={{fontSize:"1rem",textTransform:"capitalize"}}>{props.data.value.id}: {props.data.value.value}</span>
            <div>
                <p>Her kan du læse om hvad det betyder at få en høj eller lave score her eller noget</p>
            </div>
        </div>
    )
}

const labels = {
    "EN": [
        "Demotivation",
        "Learning",
        "Mastering",
        "Motivation",
        "Well-being"
    ],
    "DK": [
        "demotivation",
        "læring",
        "mastering",
        "motivation",
        "trivsel"
    ]
}

function BoxChart({data,active, lang}) {
    
    const [height, setheight] = useState(200);
    const [dataState, setdataState] = useState(data);
    
    // useEffect(() => {
    //     // changeLang()
        
    //     console.log(data);
    // }, [data]);

    function changeLang() {
    }
    //initializing hack 
    useEffect(() => {
        setTimeout(() => {
            setheight(height > 200 ? 200 : 201)
            
        }, 200);
    }, [active,lang]);

    return (
        <div style={{ height: height, display:`${active ? "block" : "none"}`}}>
            {active}
            <ResponsiveBar
                data={data}
                keys={data.map(el => el.id)}
                // keys={data.map(el => labels[lang][parseInt(el.id)])}
                margin={{ top: 50, right: 10, bottom: 50, left: 10 }}
                padding={0.3}
                minValue={0}
                maxValue={100}
                valueScale={{ type: 'linear' }}
                indexBy='label'
                indexScale={{ type: 'band', round: true }}
                colors={data.map((d) => d.color)}
                axisTop={null}
                axisRight={null}
                enableGridX={false}
                enableGridY={false}
                axisBottom={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    // legend: 'country',
                    legendPosition: 'middle',
                    legendOffset: 32,

                }}
                axisLeft={null}
                labelSkipWidth={12}
                labelSkipHeight={12}
                tooltip={value => <Tooltip data={{value}} />}
                legends={[]}
            />
    </div>

    );
}

export default BoxChart;