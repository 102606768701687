import React, { useState, useEffect,useCallback,useContext } from 'react';
import styled from 'styled-components';
import RootContainer from "../styled/RootContainer"
// import getAllusers from '../functions/getAllusers';
// import getOneCompany from '../functions/getOneCompany'
// import UserBar from '../components/UserBar';
import { getAllTeamMembers, getAllUsersFromCompany,getAllCompanies, getUserTeamRelation,getInvitedUsers } from '../functions/strapiGet';
import DashboardGraphs from '../components/DashboardGraphs';
import UserBars from '../components/UserBars';
import LanguageSwitcher from '../components/LanguageSwitcher';
import { Link, useParams , useNavigate } from 'react-router-dom';
import TeamSelector from '../components/TeamSelector';
import { FiExternalLink,FiLogOut } from "react-icons/fi";
import MenuBox from '../components/MenuBox';
import { StateContext } from '../data/stateContext';
import logo from '../data/img/logo.png'
import { device } from '../styled/device';
import { ErrorBox } from '../styled/ErrorBox';
import SetPeriod from '../components/SetPeriod'
import { SubmitButton } from "../styled/StyledSubmit";
import { UserCount } from '../components/UserCount';

const DashBoardContainer = styled.div`
    max-width:90vw;
    
    @media ${device.mobileM} {
        margin:0 auto;
        height:100vh;
        /* overflow:hidden; */
        #flexContainer {
            flex-direction:column;
            margin:0rem;
            height:100vh;
            align-items:center;

        }
        #userbars {
            width:calc(100% - 4rem);
            height:50vh;
        }
    }
    @media ${device.laptop} {
        margin-left:23rem;
        #flexContainer {
            flex-direction:row-reverse;
            justify-content:flex-end ;
            margin:15rem 4rem;
            
        }
        #userbars {
            width:40%;
        }
    }
    /* @media ${device.tablet} {
        #flexContainer {
            flex-direction:column;
            margin:0rem;
            height:100vh;
            align-items:center;

        }
        #userbars {
            width:calc(100% - 4rem);
            height:50vh;
        }
    } */
    
    #flexContainer {
        display:flex;
        /* align-items:center; */
        
    }
    #links {
        display:flex;
        flex-direction: column;
        a {
            margin:0.25rem 0;
        }
    }
`


function LederDashboard() {

    const [allUsers, setAllUsers] = useState([]);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [selectedTeam, setselectedTeam] = useState();
    const [hasFetched, sethasFetched] = useState(false);
    const [selectIndex, setselectIndex] = useState(0);
    const [lang, setlang] = useState("EN");
    const [teamRooster, setTeamRooster] = useState([]);
    const [hasErrors, sethasErrors] = useState(false);
    const [allTeamsInCompany, setAllTeamsInCompany] = useState();
    const [errorMessage,setErrorMessage] = useState()
    const [company, setcompany] = useState({id:null,name:""});
    const [allCompanies, setallCompanies] = useState([]);


    // const { company } = useParams();

    const {isAdmin, user, isLoggedIn, locale ,saveJwt,setUser, jwt, reload} = useContext(StateContext)
    const navigate = useNavigate();

    useEffect(() => {
        // console.log(user);
        if(user) {
            // console.log(user);
            if(user.position === "superadmin") {
                getAllCompanies()
                .then(json => {
                    // console.log(json);
                    const cList = json.data.map(c => {return {id:c.id,name:c.attributes.name}})
                    // json.data.forEach(c => console.log(c))
                    setallCompanies(cList)
                })
            }

            setTeamRooster([])

            // console.log(user);
            if(user.position === "manager" && !user.team) {
                // alert("not team!")
                setErrorMessage(<ErrorBox message={"You are not part of any team, contact your supervisor"} prompt={["Close"]} cb={[()=>navigate("/")]}/>)
            }
            if(user.position === "employee") {
                navigate("/")
                return
            }

            // console.log(user.company.id)
            fetchUsers(user.company.id, user.company.name)
            // getAllUsersFromCompany(user.company.id)
            //     .then(res => {
            //         if(res.error) {
            //             sethasErrors(res.error.message)
            //             return
            //         }
            //         // const fetchedUsers = [...res.employees.data]
            //         // res.employees.data.forEach(u => {
            //         //     getUserTeamRelation(u.id, jwt).then(res => {
            //         //         console.log(res);
            //         //     })
            //         // })
            //         setAllUsers([...res.employees.data])
            //         // console.log(res.employees.data);

            //         setSelectedUsers([...res.employees.data])

                    
            //         setAllTeamsInCompany([...res.teams.data])
            //         // setselectedTeam("test")
            //         // console.log(res.teams.data);
            //         sethasFetched(true)
            //     })
            // if(user.position === "manager") {
            //     setselectedTeam(user.team.name)
            // }

        }
        // setselectIndex(0)

        window.addEventListener("keydown", handleKeyDown)
        return () => {}

    }, [user]);

    const fetchUsers = (companyId, companyName) => {
        getAllUsersFromCompany(companyId)
        .then(res => {
            if(res.error) {
                sethasErrors(res.error.message)
                return
            }
            // const fetchedUsers = [...res.employees.data]
            // res.employees.data.forEach(u => {
            //     getUserTeamRelation(u.id, jwt).then(res => {
            //         console.log(res);
            //     })
            // })
            setAllUsers([...res.employees.data])
            // console.log(res.employees.data);

            setSelectedUsers([...res.employees.data])

            
            setAllTeamsInCompany([...res.teams.data])
            setcompany({id:companyId,name:companyName})
            // setselectedTeam("test")
            // console.log(res.teams.data);
            sethasFetched(true)
        })
    // if(user.position === "manager") {
    //     setselectedTeam(user.team.name)
    // }

    }


    useEffect(() => {
        if(isLoggedIn === 0) {
            navigate("/login")
        }
    }, [isLoggedIn]);

    useEffect(() => {
        // console.log(allTeamsInCompany);
        if(allTeamsInCompany) {
            // console.log(allTeamsInCompany);
            getAllTeamMembers(allTeamsInCompany, allUsers, company.id)
            .then(res => {
                // console.log(res);
                setTeamRooster(res)
                if(user.position === "manager") {
                    setselectedTeam(user.team.name)
                }

            })
        }
        
    }, [allTeamsInCompany, user]);

    useEffect(() => {
        // const filteredUsers = 

        // console.log(selectedTeam, teamRooster);
        if(teamRooster.length > 0 && selectedTeam) {
            const getTeam = teamRooster.filter(t => t.name === selectedTeam)
            // console.log(getTeam);
            // if(getTeam.length > 0) {}
            const getTeamMembers = getTeam[0].members.map(m => m.id)
            // console.log(getTeamMembers);

            const getusers = allUsers.filter(u => getTeamMembers.includes(u.id))

            // console.log(getusers);
            setSelectedUsers(getusers)
        }
        if(teamRooster.length > 0 && !selectedTeam) {
            setSelectedUsers(allUsers)
        }
        setselectIndex(0) 

    }, [selectedTeam, teamRooster]);
    

    const handleKeyDown = useCallback((e) => {
        // e.preventDefault()
        let direction = e.key === "ArrowUp" ? -1 : e.key === "ArrowDown" ? 1 : 0;
        // console.log(allUsers, teamRooster);
        const newIndex = direction + selectIndex
        if(newIndex < 0) {
            setselectIndex(selectedUsers.length - 1)
            return
        }
        switch (true) {
            case newIndex < 0:
                setselectIndex(selectedUsers.length - 1)
                break;
            case newIndex > (selectedUsers.length - 1):
                setselectIndex(0)
                break;
            default:
                setselectIndex(newIndex)
                break;
        }

    })

    useEffect(() => {
        window.addEventListener("keydown", handleKeyDown);
    
        return () => {
            window.removeEventListener("keydown", handleKeyDown);
        };
      }, [handleKeyDown]);

      const handleLogout = () => {
        saveJwt(null)
        setUser(null)
        navigate('/login')

    } 

    const handleFeedback =(e) => { 
        const url = locale === "en" ? "https://paretify.com/en/motivatore/feedbackmotivator/" : "https://paretify.com/motivator/feedback-motivator/"
        window.location.href = url
    }


 


    return (
        <>
        {errorMessage ? errorMessage
        :
            <DashBoardContainer>

                <div id="flexContainer">

                    {hasFetched ? <DashboardGraphs lang={locale} allUsers={selectedUsers} selectIndex={selectIndex} style={{margin:0}}/> : null}
                    {teamRooster.length > 0 ? <UserBars 
                        // lang={lang}
                        // style={{width:"40%"}}
                        jwt={jwt}
                        selectedTeam={selectedTeam}
                        allUsers={selectedUsers} 
                        hasFetched={hasFetched} 
                        setIndex={setselectIndex}
                        selectIndex={selectIndex}
                    /> : null}
                </div>
                {/* <UserBarContainer> 
                    {userBars}

                </UserBarContainer> */}

                <MenuBox>
                    <div>
                        <img src={logo} alt="Paretify Logo"></img>
                        <h1>DASHBOARD</h1>
                        <h1>{company.name}</h1>
                        {allCompanies.map(c => {
                            if(user.position === "superadmin") {
                                return  <button onClick={()=>fetchUsers(c.id,c.name)}>{c.name}</button>

                            }
                        })}
                       
                        {/* {user && user.position === "manager" ? 
                            <h2>team: {user ? user.team.name : null}</h2>
                        : null
                        } */}
                    </div>
                    <LanguageSwitcher pos={["bottom:3rem;","left:3rem"]}/>
                    {/* <ManagerNavigation /> */}
                    <div id="links">
                        <Link to={`/`}>Home <FiExternalLink /></Link>
                        {/* {isAdmin ? <Link to={`/manage_users/`}>Teams Managment <FiExternalLink /></Link> : null} */}
                        <a href="" onClick={handleLogout}>Logout <FiLogOut/></a>
                    </div>
                    {user && user.position === "admin" || "superadmin" ? 
                        <TeamSelector 
                            teams={teamRooster}
                            select={setselectedTeam}
                            selectedTeam={selectedTeam}
                        />
                    : null}
                    {selectedTeam ?
                    <SetPeriod selectedTeam={selectedTeam} jwt={jwt}/>
                    : null}

                    {isAdmin ? <UserCount allUsers={allUsers} jwt={jwt} user={user}/> : null}
                    <UserCount allUsers={allUsers} jwt={jwt} user={user}/>
                    <SubmitButton onClick={handleFeedback} active={true} style={{margin:"1rem 0"}}><FiExternalLink /> Send FeedBack</SubmitButton>
                </MenuBox>

            </DashBoardContainer>
        }
        </>
    );
}

export default LederDashboard;