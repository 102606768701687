import { useState } from "react"
import styled from "styled-components"
import colors from "../data/colors"

const SubmitButton = styled.button`
    position:absolute ;
    padding: ${props => props.active ? "1rem" : "0rem"};
    background-color:${colors[5]};
    border:none;
    border-radius: 5px;
    /* margin:0 auto; */
    overflow: hidden;
    height:${props => props.active ? "3em" : "0em"};
    color:white;
    display: block ;
    /* display:${props => props.active ? "block" : "none"} ; */
    transition: padding 0.2s ease-in-out, height 0.2s ease-in-out;
    cursor:pointer;
    transform: translate(-1rem, 2rem) scale(0.7) ;
    transform-origin:center center;
    &:active {
        transform:translate(-1rem, 2rem) scale(0.6) ;
    }
    &:hover {
        transform:translate(-1rem, 2rem) scale(0.9) ;
        background-color:${colors[2]} ;
    }
`

export default ({userID}) => {

    const [active, setactive] = useState(false);
    const [hasRequested, setHasRequested] = useState(false)

    setTimeout(() => {
        setactive(true)
    }, 2000);

    const handleClick = (e) => {
        setHasRequested(true)
    }

    return (
        <>
            {hasRequested ? 
            <div>meeting requested</div>
            :
            <SubmitButton onClick={handleClick} active={active}>Request Meeting</SubmitButton>
            }
        </>
    )
}