import styled from "styled-components";
import colors from "../data/colors";

export default styled.div`
    box-shadow:2px 2px 9px ${colors[2]};
    max-height:${props => props.active ? "50vh" : "0vh"};
    background-color: ${colors[1]};
    border-radius:5px;
    transition: all 0.5s ease-in-out;
    padding:${props => !props.active ? "0 2rem" : "4rem 2rem"};
    color: ${colors[4]};
    overflow:hidden;
    margin-bottom:${props => !props.active ? "0rem" : "3rem"};
    h1 {
        font-weight: bold;
        margin-bottom:1em ;
    }
    p {
        white-space:pre-wrap;
        margin-bottom: 1em;
    }

`