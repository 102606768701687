// import validator from "validator";
import { useState, useEffect, useContext } from "react";
import isStrongPassword from 'validator/lib/isStrongPassword'
import isEmail from 'validator/lib/isEmail'
// import { registerUser } from "../functions/getFunctions";
import LanguageSwitcher from "../components/LanguageSwitcher";
import TextBox from '../styled/TextBox'
import { SubmitButton } from "../styled/StyledSubmit";
import { LoginForm, InputBox } from "../styled/LoginForm";

import { Link, useNavigate } from 'react-router-dom';
import { getCompanyId, postNewUser } from "../functions/strapiGet";
import { StateContext } from "../data/stateContext";
import { ErrorBox } from "../styled/ErrorBox";
import Logo from "../styled/Logo";

const localCopy = {
    "en": {
        fname:"First Name",
        lname:"Last Name",
        password:"Password",
        repeat:"Reapeat password",
        strongness:["Strong Password","Weak Password"],
        isSame:"Not identical"
        
    },
    "da-DK": {
        fname:"Fornavn",
        lname:"Efternavn",
        password:"Adgangskode",
        repeat:"Gentag adgangskode",
        strongness:["Stærkt kodeord", "Svagt kodeord"],
        isSame:"Ikke ens"
    }
}
export const passwordRules = { 
        
    minLength: 8, 
    minLowercase: 1,
    minUppercase: 1, 
    minNumbers: 1, 
    minSymbols: 0, 
    returnScore: false, 
    pointsPerUnique: 1, 
    pointsPerRepeat: 0.5, 
    pointsForContainingLower: 10, 
    pointsForContainingUpper: 10, 
    pointsForContainingNumber: 10, 
    pointsForContainingSymbol: 10 
}

export default () => {
    // const { company } = useParams();
    // const [companyId, setcompanyId] = useState();
    const [isDone, setisDone] = useState(false);
    const [hasErrors, sethasErrors] = useState();

    const {allCopy, locale} = useContext(StateContext)
    // const [copy, setcopy] = useState();

    const [registerData, setRegisterData] = useState({
        "username":"",
        "fname":"",
        "lname":"",
        "email":"",
        "password":"",
        "company":[],
        "team":[],
        "locale":""
    });

    const navigate = useNavigate()
    
    // useEffect(() => {
    //     //GET COMPANY ID TO SET RELATION
    //     if(company) {
    //         getCompanyId(company).then(res => setcompanyId(res));
    //     }
    // }, [company]);

    // useEffect(() => {
    //     if(companyId) {
    //         const newRegData = {...registerData}
    //         newRegData.company = [companyId]
    //         setRegisterData(newRegData)
    //         // console.log(newRegData);
    //     }

    // }, [companyId]);

    useEffect(() => {
        if(allCopy !== "fetching") {
            // const copyParsed = {
            //     intro: parse(allCopy.register_intro)
            // }
            // setcopy(copyParsed)
        }
    }, [allCopy]);

 

    const [passwordCheck, setPasswordCheck] = useState("");
    const [strongness, setStrongness] = useState(null);
    const [isSame, setisSame] = useState(false);
    const [isEmailCheck, setIsEmailCheck] = useState(false)

    const handleOnSubmit = (e) => {
        e.preventDefault();

        // function makeid(length) {
        //     var result           = '';
        //     var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        //     var charactersLength = characters.length;
        //     for ( var i = 0; i < length; i++ ) {
        //       result += characters.charAt(Math.floor(Math.random() * charactersLength));
        //    }
        //    return result
        // }

        if(isSame) {
            // console.log(registerData);

            const {fname,lname} = registerData

            const genereatedUserName = `${fname} ${lname}`


            // const userData = {...registerData,"company":companyId}
            const userData = {
                ...registerData, 
                username:genereatedUserName, 
                locale:locale,
                firstName:registerData.fname,
                lastName:registerData.lname
            }
            delete userData["fname"]
            delete userData["lname"]
            // console.log(userData);
            //uncomment to activate registration
            postNewUser(userData).then(res => {
                // console.log(res);
                if(res.user) {
                    setisDone(true)
                    return
                }
                if(res.error) {
                    sethasErrors(<ErrorBox
                        message={res.error.message}
                        prompt={["Close"]}
                        cb={[()=>sethasErrors(null)]}
                    />)

                }
            })
            


            // const newUserPromise = postNewUser(isFetching,companyName)
            //const submitPromise = registerUser(registerData)
            // submitPromise.then(data => {
            //     if(data.err) {
            //         //MAKE NOTIFiCATION COMPONENT
            //         return;
            //     }
            //     setisSubmitted(true)
            // })
        }
    }
    const handleChange = (e) => {
        // console.dir(e.target.name);
        const inputData = {...registerData}
        if(e.target.name === "password") {
            // console.log(isStrongPassword(e.target.value, {...passwordRules, returnScore:false}));
            setStrongness(isStrongPassword(e.target.value, passwordRules))
        } else {
            e.target.dataset.touched = true;
        }
        if(e.target.name === "email") {
            setIsEmailCheck(isEmail(e.target.value))
        }
        inputData[e.target.name] = e.target.value
        setRegisterData(inputData)
    }
    const handleRepeat = (e) => {
        const repeatedPass = e.target.value
        // e.target.dataset.touched = true;
        setPasswordCheck(repeatedPass)
    }
    useEffect(() => {
        if(passwordCheck === registerData.password && registerData.password !== "") {
            setisSame(true)
        } else {
            setisSame(false)
        }
    }, [passwordCheck,registerData.password]);

    return (
        <>
        <Logo nav={()=> navigate("/")} />
        <TextBox style={{height:"65vh",transform: "translate(0px, 10rem)", top:"5rem"}}>
            {isDone ? 
            <div id="success">
                {allCopy.register_success}
                <br/>
                <br/>
                <br/>
                <Link to={"/login"}>Login</Link>
            </div>
            :
            <>
            <div className="login">
                {/* <h1>REGISTER</h1> */}
                <div className="text">{allCopy.register_intro}</div>
            </div>
            <LoginForm onSubmit={handleOnSubmit} isStrong={strongness} isSame={isSame}>

                <label htmlFor="fname">{localCopy[locale].fname}</label>
                <InputBox 
                    name='fname' 
                    type="text" 
                    value={registerData.fname} 
                    onChange={handleChange}
                    placeholder={localCopy[locale].fname}
                    required={true}
                    data-touched={false}
                    valid={registerData.fname !== ""}
                    />

                <label htmlFor="lname">{localCopy[locale].lname}</label>
                <InputBox 
                    name='lname' 
                    type="text" 
                    value={registerData.lname} 
                    onChange={handleChange}
                    placeholder={localCopy[locale].lname}
                    required={true}
                    data-touched={false}
                    valid={registerData.lname !== ""}
                    />
                <label htmlFor="email">E-mail</label>
                <InputBox 
                    name='email' 
                    type="email" 
                    value={registerData.email} 
                    onChange={handleChange}
                    placeholder="Email"
                    required={true}
                    data-touched={false}
                    valid={registerData.email !== "" && isEmailCheck}
                />
                <label htmlFor="password">{localCopy[locale].password}</label>
                <InputBox 
                    name='password' 
                    type="password" 
                    value={registerData.password} 
                    onChange={handleChange} 
                    placeholder={localCopy[locale].password}
                    required={true}
                    data-touched={passwordCheck !== ""}
                    valid={isSame}
                />
                <div className="strongness">
                    {strongness !== null ? strongness ? localCopy[locale].strongness[0] : localCopy[locale].strongness[1] : null}
                </div>
                    {/* MAKE STRONGNESS COMPONENT <Strongness val={strongness}/> */}
                <InputBox 
                    name='passwordRepeat' 
                    type="password" 
                    value={passwordCheck} 
                    onChange={handleRepeat} 
                    placeholder={localCopy[locale].repeat}
                    required={true}
                    data-touched={isSame}
                    valid={isSame}
                />
                <div className="issame">
                    {strongness !== null ? isSame ? null : localCopy[locale].isSame : null}
                </div>
                {/* <div id="error">
                    {hasErrors ? hasErrors : null}
                </div> */}
                <SubmitButton 
                    style={{margin:"2rem 0"}}
                    type="submit"
                    disabled={!isSame}
                    active={isEmailCheck && isSame && registerData.fname && registerData.lname !== ""}
                >
                    Register
                </SubmitButton>
                <LanguageSwitcher pos={['bottom:3rem;','left:3rem;']} color={4}/>
            </LoginForm>
            </>

            }

        </TextBox>
        {hasErrors}
        </>
    )
}