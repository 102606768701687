import { useState, useContext } from "react";
import { InputBox, LoginForm } from "../styled/LoginForm";
import Logo from "../styled/Logo";
import TextBox from "../styled/TextBox";
import { ErrorBox } from "../styled/ErrorBox";
import LanguageSwitcher from "../components/LanguageSwitcher";
import isEmail from 'validator/lib/isEmail'
import { SubmitButton } from "../styled/StyledSubmit";
import { requestReset } from "../functions/strapiGet";
import { useNavigate } from 'react-router-dom';
import { StateContext } from "../data/stateContext";

function ForgotPassword() {

    const [email, setemail] = useState("");
    const [isEmailCheck, setIsEmailCheck] = useState(false)
    const [hasErrors, sethasErrors] = useState();
    const navigate = useNavigate()

    const {locale} = useContext(StateContext)

    const handleOnSubmit = (e) => {
        e.preventDefault()



        requestReset(email)
            .then(json => {
                console.log(json);
                if(json.ok) {
                    const message = locale === "en" ? `An email has been send to ${email} with further instructions.` : `Vi har sendt en email til ${email} med videre instrukser.`
                    sethasErrors(<ErrorBox
                        message={message}
                        prompt={["Close"]}
                        cb={[()=>sethasErrors(null)]}
                    />)
                } else {
                    sethasErrors(<ErrorBox
                        message={json.message}
                        prompt={["Close"]}
                        cb={[()=>sethasErrors(null)]}
                    />)
                }
            })
        
    }

    const handleChange = (e) => {
        setIsEmailCheck(isEmail(e.target.value))
        setemail(e.target.value)
    }
    return (
        <>
            <Logo nav={()=> navigate("/")} />
            <TextBox>
                <div className="login">
                    <div className="text">
                        {locale === "en" ?
                        <>
                            <p>Sorry to hear you lost your password</p>
                            <p>But don't worry, we will help you reset it</p>
                            <p>First type your email and press SEND</p>
                        </>
                        :
                        <>
                            <p>Vi er ked af at høre du har mistet din adgangskode</p>
                            <p>Men bare rolig vi hjælper dig med at lave en ny.</p>
                            <p>Udfyld din email her og tryk SEND</p>
                        </>

                        }

                    </div>
                </div>
                <LoginForm onSubmit={handleOnSubmit}>
                <label htmlFor="email">E-mail</label>
                <InputBox 
                    name='email' 
                    type="email" 
                    value={email} 
                    onChange={handleChange}
                    placeholder="Email"
                    required={true}
                    data-touched={false}
                    valid={email !== "" && isEmailCheck}
                />
                <SubmitButton 
                    style={{margin:"2rem 0"}}
                    type="submit"
                    disabled={!isEmailCheck}
                    active={isEmailCheck}
                >
                    SEND
                </SubmitButton>

                </LoginForm>
                <LanguageSwitcher pos={['bottom:3rem;','left:3rem;']} color={4}/>
            </TextBox>
            {hasErrors}
        </>
    );
}

export default ForgotPassword;