import React, { useState,useEffect } from 'react';
import styled from 'styled-components';
import colors from '../data/colors';
import { BsFillPeopleFill } from "react-icons/bs";

const TeamsConatiner = styled.div`
    /* position:absolute;
    bottom: 5rem;
    left: 3rem;*/
    width:15rem; 
    display:flex;
    flex-direction:column;
    gap:1rem;
`
const TeamCard = styled.div`
    /* height:3.5rem; */
    width:100%;
    background-color:${props => props.active ? colors[1] : colors[3]};
    padding:1rem;
    border-radius:5px;
    text-align:center;
    display:flex;
    gap:0.5rem;
    opacity: ${props => props.hasMembers ? 1 : 0.5};
    cursor: ${props => props.hasMembers ? "pointer" : "default"};
    &:hover {
        background-color: ${props => props.hasMembers ? props.active ? colors[1] : colors[2] : colors[3]};
    }
    
    & > div {
        width:80%;
        color:${props => props.active ? colors[4] : "black"};
        p {
            font-size:1rem;
            span {
                font-size:0.9rem;
                margin-right:1rem;
            }
        }
        h1 {
            font-size:1.2rem;
            text-transform:capitalize;
        }
    }
`

function Team({name,count,select,active}) {

    function handleSelect() {
        if(count > 0) {
            if(active) {
                select()
            } else {
                select(name)
            }
        }
    }

    return (
        <TeamCard active={active} onClick={handleSelect} hasMembers={count > 0}>
            <span><BsFillPeopleFill color={colors[4]} size={20}/></span>
            <div>
                <h1> {name}</h1>
                <p><span>members:</span>{count}</p>
            </div>
        </TeamCard>
    );
}



function TeamSelector({teams,select, selectedTeam}) {

    const [teamCards, setTeamCards] = useState();

    useEffect(() => {
        const comps = teams.map((team,index) => 
                <Team 
                    key={index} 
                    name={team.name}
                    count={5}
                    count={team.members.length}
                    select={select}
                    active={selectedTeam === team.name}
                />
        )
        setTeamCards(comps)
    }, [teams,selectedTeam]);


    return (
        <TeamsConatiner>
            <h1>Teams</h1>
            {teamCards}
        </TeamsConatiner>

    )
}

export default TeamSelector;