import moment from 'moment'

export default (arr, sumup) => {
    // const obj = {
    //     date:null,

    // }
    // console.log(arr);
    const intialValue = 0

    return arr.map(arr => (
        {
            value: sumup.reduce((prev,current) => arr.spread[current] + prev, intialValue ),
            // value:arr.spread[sumup[0]] + arr.spread[sumup[1]],
            date: moment.utc(arr.date,"YYYY-MM-DD\THH:mm:ss\Z").format("DD-MM-YYYY")
        }
    ))
}