import { useState, useEffect } from "react";
import styled from "styled-components";
import colors from "../data/colors";
import { getInvitedUsers } from "../functions/strapiGet";

const Card = styled.div`
    /* height:3.5rem; */
    width:100%;
    background-color:${colors[3]};
    padding:1rem;
    border-radius:5px;
    text-align:center;
    display:flex;
    gap:0.5rem;
    justify-content:center ;
    /* opacity: ${props => props.hasMembers ? 1 : 0.5}; */
    cursor: ${props => props.hasMissing ? "pointer" : "default"};
    &:hover {
        background-color: ${props => props.hasMissing ? props.active ? colors[2] : colors[2] : colors[3]};
    }
    p {
        font-size:1rem;
        span {
            font-size:0.9rem;
            margin-right:1rem;
        }
    }
    h1 {
        font-size:1.2rem;
        text-transform:capitalize;
    
    & > div {
        width:80%;
        /* color:${props => props.active ? colors[4] : "black"}; */
        }
    }
    #missing {
        /* overflow:hidden; */
        margin-top:1em;
        /* max-height:${props => !props.active ? "0" : "5rem"}; */
        text-align:left ;
        p:first-of-type {
            margin-bottom:1em;
        }
    }
    `

export const UserCount = ({allUsers, jwt, user}) => {

    const [invitedUsers,setinvitedUsers] = useState([])
    const [missingEmailComps,setmissingEmailComps] = useState([])
    const [active, setactive] = useState(false);
    const [hasMissing, sethasMissing] = useState(false);

    useEffect(() => {
        if(user) {
            getInvitedUsers(user.company.id,jwt)
            .then(res => {
                // console.log(res);
                setinvitedUsers(res)
            })

        }
    }, [user,jwt]);


    useEffect(() => {
        if(invitedUsers && allUsers) {
            // console.log(invitedUsers, allUsers);
            if(invitedUsers.length > allUsers.length) {
                sethasMissing(true)
                const invitedEmails = invitedUsers.map(u => u.email)
                const usersEmails = allUsers.map(u => u.attributes.email)
                const missingUsers = invitedEmails.filter(e => !usersEmails.includes(e))
                // console.log(missingUsers);
                const list = []
                missingUsers.forEach((u,i) => {
                    list.push(<p key={i}>{u}</p>)
                })
                setmissingEmailComps(list)
                
            }
        }
    }, [invitedUsers,allUsers]);

    const handleClick = () => {
            if(hasMissing) {
                setactive(!active)
            }
    }
    
    return (
        <Card onClick={handleClick} active={active} hasMissing={hasMissing}>
            <div>
                {!active ? 
                <>
                    <h1>User Registration</h1>
                    <p>{allUsers.length} / {invitedUsers.length}</p>
                </>
                :  
                <>
                        <h1>Missing users:</h1>
                    <div id="missing">
                        {missingEmailComps}
                    </div>
                </>
                }
            </div>
        </Card>
    )
}