import React, { useState } from 'react';
import styled from 'styled-components';
import colors from '../data/colors';
import { device } from '../styled/device';
import { FiMenu } from "react-icons/fi";

const MenuContainer = styled.div`
    height:100vh;
    position:fixed;
    display:flex;
    flex-direction:column;
    gap:3rem;

    nav {
        width:100vw;
        display:flex;
        gap:5rem ;
        align-items:flex-start ;
        #links {
            display:flex ;
            flex-direction:row ;
            gap:1rem;
        }
    }
    img {
        width:15rem;
        height: auto;
    }

    h1{
        font-size:2.3rem;
    }
    h2 {
        font-size:1.8rem;
        text-transform:capitalize;
    }
    a:visited {
        color:initial;
    }
    left:2rem;
    top:2rem;
    width:auto;
    background-color:transparent;
    #toggle {
        display:none ;
    }


    
    @media (max-width:820px) {
        transition: left 0.3s ease-in-out;
        left:${props => props.toggle ? "0rem" : "-100vw"};
        top:0rem;
        background-color:#d9d9d9f0;
        /* backdrop-filter:blur(5px) ; */
        width:50vw;
        z-index:100;
        /* margin: */
        box-sizing:border-box;
        padding:20rem 0;
        
        justify-content:flex-end;
        align-items:flex-start;
        img {
            width:10rem;
        }
        padding-left:2rem;
        #toggle {
            display:block;
            position:fixed;
            top:1rem;
            left:1rem;
            transition:transform 0.3s ease-in-out;
            transform:${props => props.toggle ? "rotate(270deg)" : "rotate(0deg)" } ;
        }
    }

    @media ${device.laptop} {
        
    }

`

function MenuBox(props) {

    const [toggle, setToggle] = useState(false);

    return (
        <MenuContainer toggle={toggle}>
            <div id="toggle" onClick={()=>setToggle(!toggle)}>
                <FiMenu size={25} />
            </div>
            {props.children}
        </MenuContainer>
    );
}

export default MenuBox;