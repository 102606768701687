import { useEffect,useState,useContext } from "react";
import { BsFillAlarmFill,BsFillCaretUpFill, BsFillCaretDownFill } from "react-icons/bs";
import styled from "styled-components";
import { getTeamPeriod, updatePeriod } from "../functions/strapiGet";
import colors from "../data/colors";
import { ErrorBox } from "../styled/ErrorBox";
import { StateContext } from "../data/stateContext";
const PeriodBox = styled.div`

    user-select: none;
    position:relative;
    display:flex;
    flex-direction:column;
    align-items: center;
    padding:1rem 2rem 1rem 2rem;
    background-color:${colors[3]};
    border-radius:15px;

    #clock {
        position: absolute ;
        top:-1rem;
        left:-1rem;
    }
    h1 {
        font-size: 1.5rem;
        margin-bottom:1em;
    }
    input[type=number]::-webkit-inner-spin-button {
        opacity: 1;
        display:none ;
        transform:scale(1.5) translate(-5rem,5rem) ;
    }
    input {
        width: 4rem;
        height: 4rem;
        border-radius: 15px;
        text-align: center;
        font-size: 2rem;
        border:none ;
    }

    #input {
        display:flex;
        
        #arrows {
            display:flex;
            margin-left:1rem;
            flex-direction:column;
            svg {
                cursor:pointer;
                &:hover {
                    transform: scale(1.2);
                }
                &:active {
                    transform: scale(0.9);
                }
            }

        }
    }
    button {
        display:block ;
        padding: 1rem;
        background-color:${colors[5]};
        border:none;
        border-radius: 5px;
        margin:0 auto;
        overflow: hidden ;
        color:white;
        height:3em;
        font-size: 1rem;
        margin-top:1rem;
        cursor:pointer;
        &:active {
            transform:scale(0.9) ;
        }
        &:hover {
            background-color:${colors[2]} ;
        }

    }
`

export default ({selectedTeam, jwt}) => {

    const [period, setPeriod] = useState();
    const [teamId, setTeamId] = useState();
    const [hasChanged, sethasChanged] = useState(false);
    const [message, setmessage] = useState();

    const {triggerReload} = useContext(StateContext)

    useEffect(() => {
        if(selectedTeam) {
            fetchTeamInfo()
        }
    }, []);

    useEffect(() => {
        if(selectedTeam) {
            fetchTeamInfo()
        }
    }, [selectedTeam]);
    
    const fetchTeamInfo = () => {
        
        getTeamPeriod(selectedTeam)
        .then(data => {
            // console.log(data);
            if(!data.message) {
                setPeriod(data.period)
                setTeamId(data.id)
            }
        })
    }

    useEffect(() => {
        // console.log(period)
        if(period < 1) {
            setPeriod(1)
        }
    }, [period]);

    const handleChange = (e) => {
        // console.log("e.target.value");
        setPeriod(e.target.value)
        sethasChanged(true)
    }
    const handleClick = (i) => {
        setPeriod(period + i)
        sethasChanged(true)
    }

    const handleSubmit = (e) => {
        // console.log(teamId,jwt);
        sethasChanged(false)
        updatePeriod(teamId,period,jwt).then(res => {
            setmessage(
                <ErrorBox 
                    message={`${selectedTeam}'s period has been changed to ${period} weeks!`}
                    prompt={["Close"]}
                    cb={[()=> {setmessage(null);triggerReload(true)}]} 
                />
            )
        })
    }
    return (
        <PeriodBox>
            <div id="clock">
                <BsFillAlarmFill size={'2.5rem'} color={colors[1]} />
            </div>
            <h1>Set period (weeks)</h1>
            <div id="input">
                <input 
                    type="number" 
                    min="1" 
                    step="1"
                    value={period}
                    onChange={handleChange}
                />
                <div id="arrows">
                    <BsFillCaretUpFill onClick={()=>handleClick(1)} size={"2rem"}/>
                    <BsFillCaretDownFill onClick={()=>handleClick(-1)} size={"2rem"}/>
                </div>
                
            </div>
            {hasChanged ? <button onClick={handleSubmit}>GEM</button> : null}
            {message ? message : null}
        </PeriodBox>
    )
}