import { RawOnSharp } from "@mui/icons-material";

const BASE_URL = process.env.REACT_APP_STRAPI_API_URL

export const getCopy = async (setFetching, locale)=> {

    const url = `${BASE_URL}motivator-copy/?populate=deep&locale=${locale}`
    let fecthedData;
    setFetching(true)
    try {
        await fetch(url)
        .then(res => res.json())
        .then(data => {
            // console.log(data)
            fecthedData = data.data.attributes
        })
        .catch(err => {
            console.error(err)
            fecthedData = false;
        })
    } catch (err) {
        console.error(err)
    }
    setFetching(false)
    return fecthedData
}

export const getUser = async (setFetching, token) => {
    const url = `${BASE_URL}users/me?populate=*`
    setFetching(true)
    let fetchedUser
    try {
        await fetch(url, {
            method:"GET",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        })
        .then(resp => resp.json())
        .then(data => {
            // console.log(data)
            fetchedUser = data;
        })
    } catch(err) {
        console.error(err);
        fetchedUser = false;
    }
    setFetching(false)
    return fetchedUser

}

export const getCompanyId = async (name) => {
    const url = `${BASE_URL}companies?filters[name][$eq]=${name}`
    let id
    // console.log(url);
    try {
        await fetch(url)
        .then(res => res.json())
        .then(data => {
            // console.log(data);
            id = data.data.length > 0 ? data.data[0].id : null;
        })
        .catch(err => console.error(`ERROR: ${err}`))
    } catch (err) {
        console.error(err);
    }
    return id
}

export const putInvitedUsers = async (json, companyName) => {

    const payload = {
        "data":{
            "invited_users": [...json]
        }
    }
    let returnMessage;
    const fetchOptions = {
        method:"PUT",
        headers: {
            'Content-Type': 'application/json'
        },
        body:JSON.stringify(payload)
    }
    try {
        let companyId = null;
        await getCompanyId(companyName)
            .then(res => {
                companyId = res
            })
            .catch(err => {
                // console.log(err);
                returnMessage = "Company doesn't exist"
            })
        if(companyId) {
            const url = `${BASE_URL}companies/${companyId}`
            await fetch(url,fetchOptions)
                .then(res => res.json())
                .then(data => {
                    returnMessage = "Done"
                })
                .catch(err => {
                    console.error(err);
                    returnMessage = "Error"
                })
        } else {
            returnMessage = "Company doesn't exist, make sure you spelled it correctly"
        }
    } catch (error) {
        console.error(error);
    }
    return returnMessage
}

export const postNewUser = async (userData) => {

    const url = `${BASE_URL}auth/local/register`
    // console.log(userData);

    let fetchedData

    const fetchOptions = {
        method:"POST",
        headers: {
            'Content-Type': 'application/json'
        },
        body:{}
    }

    try {
        let isInvitedUser = false;
        let teamName;
        await fetch(`${BASE_URL}companies/?populate=invited_users`)
            .then(res => res.json())
            .then(data => {
                data.data.forEach(c => {
                    c.attributes.invited_users.forEach(u => {
                        if(u.email === userData.email) {
                            isInvitedUser = true;
                            // console.log(userData);
                            userData.company[0] = c.id;
                            userData.position = u.role
                            teamName = u.team
                        }
                    })
                })
            })
            .catch(err => console.error(err))
        await getTeamID(teamName)
            .then(id => {
            // console.log(id);
            if(id && id.length > 0) {
                userData.team[0] = id[0];
                fetchOptions.body = JSON.stringify(userData)
            } else {
                fetchOptions.body = JSON.stringify(userData)
            }
            })
            .catch(err => console.error(err));
        if(isInvitedUser) {
            // console.log(userData);
            await fetch(url,fetchOptions)
                .then(res => res.json())
                .then(data => fetchedData = data)
                .catch(err => console.error(err))
        } else {
            fetchedData = {data:null, error:{message:"User is not on the list of invited users"}}
        }
    } catch (err) {
        console.error(err);
    }
    return fetchedData
}

export const loginUser = async (credentials) => {

    const url = `${BASE_URL}auth/local`

    const fetchOptions = {
        method:"POST",
        headers: {
            'Content-Type': 'application/json'
        },
        body:JSON.stringify(credentials)
    }

    let fetchedData 
    try {
        await fetch(url, fetchOptions)
        .then(res => res.json())
        .then(data => fetchedData = data)
        .catch(err => console.error(err))
        
    } catch (error) {
        console.error(error);
    }
    return fetchedData
}

export const putAnswers = async (id, fecthedAnswers, answers, cb, token) => {
    const url = `${BASE_URL}users/${id}`

    const d1 = new Date()
    const str = d1.toISOString()

    const addedDate = {...answers,"date":str}


    const sendData = {
        "motivator_answers":[...fecthedAnswers,addedDate]
    }
    
    const fetchOptions = {
        method:"PUT",
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        body:JSON.stringify(sendData)
    }
    let fetchedData 

    try {
        await fetch(url, fetchOptions)
        .then(res => res.json())
        .then(data => {
            // console.log(data);
            fetchedData = data
        })
        .catch(err => console.error(err))
        
    } catch (error) {
        console.error(error);
    }
    return fetchedData
}

export const getUserTeamRelation = async (userId,token) => {
    // console.log(userId);
    const url = `${BASE_URL}users/${userId}?populate=team`
    const fetchOptions = {
        method:"GET",
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    }
    let team

    await fetch(url,fetchOptions)
        .then(res => res.json())
        .then(json => team = {id:json.team.id,period:json.team.period})
        .catch(err => console.error(err))
    return team
}

export const getAllUsersFromCompany = async (companyId) => {
    const url = `${BASE_URL}companies/${companyId}?populate=*`


    let fetchedData 

    try {
        await fetch(url)
        .then(res => res.json())
        .then(json => {
            // console.log(json);
            if(json.error) {
                fetchedData = json
            } else {
                fetchedData = json.data.attributes
                // json.data.attributes.employees.forEach()
                // console.log(json.data.attributes);
            }
        })
        .catch(err => console.error(err))
        
    } catch (error) {
        console.error(error);
    }
    return fetchedData

    // console.log(companyId);
}

export const getAllTeamMembers = async (arrTeams,allUsers, companyID) => {

    const fecthedTeams = []
    let queryString = ""
    arrTeams.forEach((team, index) => queryString += `&filters[id][$in][${index}]=${team.id}`)
    const url = `${BASE_URL}teams/?populate=*${queryString}`

    try {
        await fetch(url)
        .then(res => res.json())
        .then(data => {
            // console.log(data);
            // console.log(allUsers);
            data.data.forEach(t => {
                const members = allUsers.map(m => m.id)
                //CHECK THAT WE ONLY GET TEAM MEMBERS FROM CURRENT COMPANY AND ONLY TEAMS ATTACHED TO COMPANY
                const userIds = t.attributes.users.data.filter(user => members.includes(user.id))
                const teamObj = {
                    name:t.attributes.name,
                    members:userIds
                }
                fecthedTeams.push(teamObj)
            })
            // console.log(fecthedTeams);
        })
        .catch(err => console.error(err))
        
    } catch (error) {
        console.error(error);
    }
    return fecthedTeams
}

export const putUserTeamRelation = async (user, teamname) => {

    const url = `${BASE_URL}users/${user.id}`
    const fetchOptions = {
        method:"PUT",
        headers: {
            'Content-Type': 'application/json'
        },
        body:null
    }
    let hasFetched
    try {
        await getTeamID(teamname)
        .then(id => {

            fetchOptions.body = JSON.stringify({"team":id})
            // console.log(id);
            fetch(url,fetchOptions)
                .then(res => res.json())
                .then(data => {
                    // console.log(data);
                    hasFetched = true
                })
                .catch(err => {
                    console.error(err);
                })
        })
        
    } catch (error) {
        
    }
    return hasFetched
}


export const getTeamID = async (teamName) => {
    if(teamName === null) {
        return []
    }
    const url = `${BASE_URL}teams/?filters[name][$eq]=${teamName}`
    let fecthedData
    try {
        await fetch(url)
        .then(res =>res.json())
        .then(data => {
            // console.log(data.data[0].id);
            // console.log(data);
            fecthedData = data.data.length > 0 ? [data.data[0].id] : false;
        })
        .catch(err => {
            console.error(err);
        })
    } catch (error) {
        console.error(error);
        
    }
    return fecthedData
}

export const putNewTeam = async (team, companyId, token = null) => {

    const url = `${BASE_URL}teams/`

    const teamObj = {
        "data": {
            "name":team,
            "company":[companyId],
            "period":3
        }
    }
    let hasFetched

    const fetchOptions = {
        method:"POST",
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        body:JSON.stringify(teamObj)
    }
    if(!token) {
        delete fetchOptions.headers.Authorization
    }

    try {
        await fetch(url,fetchOptions)
        .then(res => res.json())
        .then(data => console.log(data))
    } catch (error) {
        
    }

    return hasFetched
}

export const getTeamPeriod = async (teamName) => {
    // console.log(teamName);
    const url = `${BASE_URL}teams/?filters[name][$eq]=${teamName}&populate=period`
    let fetchedData
    try {
        await fetch(url)
        .then(res => res.json())
        .then(data => {
            // console.log(data);
            if(data.data.length > 0) {
                fetchedData = {
                    id:data.data[0].id,
                    period:data.data[0].attributes.period, 
                    message:null}
            } else {
                fetchedData = {message:"No period found"}
            }
        })
        .catch(err => console.error(err))
        
    } catch (error) {
        console.error(error)
    }
    return fetchedData

}

export const updatePeriod = async (teamId,period,token) => {
    const url = `${BASE_URL}teams/${teamId}`

    const fetchOptions = {
        method:"PUT",
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({"data":{"period": period}})
    }

    try {
        await fetch(url,fetchOptions)
            .then(res => res.json())
            .then(res => console.log(res))
            .catch(err => console.error(err))
    } catch (error) {
        
    }

    return "done!"
}


export const getInvitedUsers = async (companyID, token) => {
    const url = `${BASE_URL}companies/${companyID}?populate=invited_users`
    const fetchOptions = {
        method:"GET",
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    }
    let response
    try {
        await fetch(url,fetchOptions)
            .then(resp => resp.json())
            .then(json => {
                // console.log(json);
                response = json.data.attributes.invited_users
            })
        
    } catch (error) {
        
    }
    return response

}

export const getAllCompanies = async () => {
   const url = `${BASE_URL}companies/`

   let response

   try {
        await fetch(url)
        .then(res => res.json())
        .then(json => response = json)
        .catch(err => console.error(err))
   } catch (error) {
        console.error(error);
   }

   return response
}

export const updatePassword = async (data) => {

    const url = `${BASE_URL}auth/reset-password`

    const fetchOptions = {
        method:"POST",
        headers: {
            'Content-Type': 'application/json'
        },
        body:JSON.stringify(data)
    }

    let token;

    try {

        await fetch(url,fetchOptions)
            .then(res => res.json())
            .then(json => {

                token = json
                // if(json.error) {
                //     token = json.error.message
                // } else {
                //     console.log(json);
                // }
                // token = json.jwt
            })
    } catch (error) {
        
    }

    return token
}

export const checkIfUser = async (email) => {
    const url = `${BASE_URL}users/?filters[email][$eq]=${email}`
    let response 

    await fetch(url)
            .then(res => res.json())
            .then(json => response = json.length > 0)
            .catch(err => console.error(err))

    return response
}

export const requestReset = async (email) => {

    let response;
    // checkIfUser(email)
    //     .then(res => {
    //         if(!res) {
    //             response = "Not a User"
    //             return response
    //         }
    //         if(res) {
    //             const url = `${BASE_URL}auth/forgot-password`
            
    //             const data = {"email": email}
            
    //             const fetchOptions = {
    //                 method:"POST",
    //                 headers: {
    //                     'Content-Type': 'application/json'
    //                 },
    //                 body:JSON.stringify(data)
    //             }
    //             fetch(url,fetchOptions)
    //                 .then(res => res.json())
    //                 .then(json => {
                        
    //                     response = json
    //                     return response
    //                 })
            
    //         }
    //     })
    let isUser
    try {
        await checkIfUser(email)
            .then(res => {
                isUser = res
            })
        if(!isUser) {
            response =  {ok:false,message:`No users registered with ${email}`}
        } else {
            const url = `${BASE_URL}auth/forgot-password`
            
            const data = {"email": email}
        
            const fetchOptions = {
                method:"POST",
                headers: {
                    'Content-Type': 'application/json'
                },
                body:JSON.stringify(data)
            }
            await fetch(url,fetchOptions)
                .then(res => res.json())
                .then(json => {
                    response = json
                })
            }
    } catch (error) {
        
    }
    return response
}