import React, {useState,useEffect, useContext} from 'react';
import colors from '../data/colors';
import Slider from '../styled/Slider';
import styledComp from 'styled-components';
import AnimatedContainer from '../styled/AnimatedContainer';

import PropTypes from 'prop-types';
import Rating, { IconContainerProps } from '@mui/material/Rating';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import SentimentDissatisfiedIcon from '@mui/icons-material/SentimentDissatisfied';
import SentimentSatisfiedIcon from '@mui/icons-material/SentimentSatisfied';
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAltOutlined';
import SentimentVerySatisfiedIcon from '@mui/icons-material/SentimentVerySatisfied';
import { styled } from '@mui/material/styles';
import { StateContext } from '../data/stateContext';
// import SentimentSatisfiedIcon from '@mui/icons-material/SentimentSatisfied';
const customIcons = {
    1: {
      icon: <SentimentVeryDissatisfiedIcon />,
      label: 'Very Dissatisfied',
    },
    2: {
      icon: <SentimentDissatisfiedIcon />,
      label: 'Dissatisfied',
    },
    3: {
      icon: <SentimentSatisfiedIcon />,
      label: 'Neutral',
    },
    4: {
      icon: <SentimentSatisfiedAltIcon />,
      label: 'Satisfied',
    },
    5: {
      icon: <SentimentVerySatisfiedIcon />,
      label: 'Very Satisfied',
    },
};

// const ratingLUT = [1,1,2,2,3,3,4,4,5,5]

function IconContainer(props) {
    const { value, ...other } = props;
    return <span {...other}>{customIcons[value].icon}</span>;
}

IconContainer.propTypes = {
    value: PropTypes.number.isRequired,
};

const StyledRating = styledComp(Rating) ({

    '& .MuiRating-iconEmpty': {
        color: colors[2],
        // transform:"scale(2)"
    },
    '& .MuiRating-iconFilled': {
        color: colors[3],
        // transform:"scale(2.5)"
    },
    '& .MuiRating-icon': {
        width:"6rem",
        // color: colors[3]
        // transform:"scale(2.5)"
    },
    '& .MuiSvgIcon-root': {
        // color: colors[5],
        fontSize:"5rem",
        // color: colors[3]
        // width:"5rem",
    }
})

const SliderContainer = styled(AnimatedContainer)`

    

    div {
        margin-bottom:5rem ;
    }

`

  
function PersonalRating(props) {

    const [active, setactive] = useState(false);
    const [gradient, setgradient] = useState(`linear-gradient(90deg,${colors[2]} 45%, ${colors[2]} 45%, transparent 0%, transparent 100%)`);
    const [floatedRating, setFloatedRating] = useState(50);
    const {rating, setRating, setProgress, progress, lang} = props

    const {allCopy} = useContext(StateContext)

    useEffect(() => {

        setTimeout(() => {
            setactive(true)
            
        },500)
    }, []);
    
    useEffect(() => {
        // console.log(progress)
        if(progress > 6) {
            setactive(false)
        }
    }, [progress]);

    // const [rating, setrating] = useState(0);


    function handleUp() {
        // if(props.index === props.progress) {
            // }
            setProgress(6)
    }
    
    function handleChange(e) {
        const ratingfloat = e.target.value
        const roundedrating = Math.round(e.target.value/10)
        // setProgress(6)
        // if(props.progress < 6) {
        // }
        // console.log(Math.round(roundedrating/2));
        setRating(parseInt(roundedrating))
        setFloatedRating(ratingfloat)
    }
    useEffect(() => {
        setgradient(`linear-gradient(90deg,${colors[2]} ${(floatedRating)-5}%, ${colors[2]}  ${(floatedRating)-5}%, transparent 0%, transparent 100%)`)
        
    }, [floatedRating]);

    function handleClickChange(e) {
        const val = e.target.value;
        setRating((val*2)-1)
        setFloatedRating((val*20)-10)
        setProgress(6)
    }

    //const gradient = `linear-gradient(90deg,${colors[2]} ${rating*10}%, ${colors[2]}  ${rating*10}%, transparent 0%, transparent 100%)`

    // useEffect(()=>{
    //     // setmax(props.left + props.precent)
    //     setgradient(`linear-gradient(90deg,${colors[2]} ${props.precent}%, ${colors[2]}  ${props.precent}%, transparent 0%, transparent 100%)`)
    // },[props.left])
    return ( 
        <SliderContainer active={active}>
            <div>
                {allCopy.personal_rating}
            </div>
            {/* <p style={{margin:"2rem 0"}}>Rate your result </p> */}
            <Slider active={true} string={rating} clickAble={true} style={{marginBottom:"2rem"}}>
                <div className='bar'></div>
                <input 
                        type="range"
                        min={10}
                        max={100}
                        value={floatedRating}
                        onChange={handleChange}
                        // onMouseDown={handleDown}
                        onMouseUp={handleUp}
                        onTouchEnd={handleUp}
                        style={{ backgroundImage: gradient }}
                    />
            </Slider>
            <h1 style={{display:"flex",justifyContent:"center", fontSize:"4rem"}}>
                {rating} {lang === "en" ? "out of 10" : "ud af 10"}
            </h1>
            <div style={{display:"flex",justifyContent:"center"}}>
                <StyledRating
                    // name="highlight-selected-only"
                    name="size-large"
                    // defaultValue={rating}
                    value={Math.round(rating/2)}
                    key={2}
                    IconContainerComponent={IconContainer}
                    highlightSelectedOnly
                    // size="large"
                    onChange={handleClickChange}
                />

            </div>
        </SliderContainer>
     );
}

export default PersonalRating;