import styled from 'styled-components';

const RootContainer = styled.div`
  max-width:50rem;
  width:calc(100% - 4rem);
  margin: 0 auto;
  @media (max-width: 768px) {
    padding: 8rem 0;
        /* min-height:calc(100vh - 8rem); */
  }
  /* & > * {
    visibility: ${props => props.isFetching ? "hidden" : "visible"};
    
  } */
  /* transition: filter 0.5s linear; */
  /* filter: ${props => props.isFetching ? "blur(5px)" : "blur(0px)"}; */
`

export default RootContainer