import React, {useEffect, useState, useContext} from 'react';
import answers from "../data/answers.json"
import { StateContext } from '../data/stateContext';
import ResultContainer from '../styled/AnimatedContainer';
import parse from 'html-react-parser';
import Logout from './Logout';


function Result(props) {

    const {resultsCopy} = useContext(StateContext)

    const [active, setactive] = useState(false);
    const [ratingIndex, setratingIndex] = useState();
    const [hasFecthed, sethasFecthed] = useState(false);
    const [resultObject, setResultObj] = useState()
    useEffect(() => {
        const rating = props.rating < 4 ? 0 : props.rating < 8 ? 1 : 2;
        setratingIndex(rating)
        setTimeout(() => {
            setactive(true)
            
        },500)
    }, []);

    useEffect(() => {

        if(resultsCopy) {

            const resultObj = {}
            resultsCopy.forEach(res => {
                resultObj[res.name] = {
                    title: res.title,
                    outcomes: [...res.outcomes]
                }
            });
            setResultObj(resultObj)
            // console.log(resultObj);
            sethasFecthed(true)
        }
        
    }, [resultsCopy]);
    
    // const ratingIndex = props.rating < 4 ? 0 : props.rating < 8 ? 1 : 3 //0-3
    // console.log(ratingIndex);
    // 0 (1, 2, 3)
    // 1 (4, 5, 6, 7)
    // 3 (8, 9, 10)

    return (
        <>
            {hasFecthed ? 
            <>
                <ResultContainer active={active} style={{whiteSpace:"break-spaces"}}>
                    <h1>{resultObject[props.result[0]].title}</h1>
                    <p>{parse(resultObject[props.result[0]].outcomes[ratingIndex].text)}</p>
                </ResultContainer>
                {/* <Logout/> */}
            </>
            :
                null
            }
        </>
    );
}

export default Result;