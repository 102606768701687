// install (please make sure versions match peerDependencies)
// yarn add @nivo/core @nivo/line
import {useState, useEffect} from 'react';

import { ResponsiveLine } from '@nivo/line'



const Tooltip = (props) => {
    return (
        <span style={{fontSize:"1rem",textTransform:"capitalize"}}>{props.data.name} : {props.data.val}</span>
    )
}


const LineGraph = ({ graphsToShow, data}) => {

    // console.log(data)

    const [dataState, setdataState] = useState([]);


    useEffect(() => {
        setdataState([])
        const newState = [data[4]]
        
        graphsToShow.forEach(index => newState.push(data[index]))

        setdataState(newState)
    }, [graphsToShow,data]);

    return (
    <div style={{ height: "35vh" }}>
    {dataState ? 
    <>
    <ResponsiveLine
        data={dataState}
        margin={{ top: 50, right: 50, bottom: 50, left: 60 }}
        // width={80}
        // height={400}
        xScale={{ type: 'point' }}
        yScale={{
            type: 'linear',
            min: 0,
            max: 100,
            stacked: false,
            reverse: false
        }}
        yFormat=" >-.2f"
        curve="monotoneX"
        axisTop={null}
        axisRight={null}
        axisBottom={{
            orient: 'bottom',
            tickSize: 3,
            tickPadding: 5,
            tickRotation: -45,
            // legend: 'Time',
            legendOffset: 36,
            legendPosition: 'middle'
        }}
        axisLeft={{
            // tickValues:'Points',
            orient: 'left',
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            // legend: 'count',
            legendOffset: -40,
            legendPosition: 'middle'
        }}
        pointSize={2}
        enablePoints={true}
        pointColor={{ theme: 'background' }}
        // colors={{ scheme: 'purple_blue' }}
        // colors={{ scheme: 'category10' }}
        colors={dataState.map((d) => d.color)}
		colorBy="index"
        lineWidth={3}
        pointBorderWidth={5}
        pointBorderColor={{ from: 'serieColor' }}
        enablePointLabel={false}
        pointLabel="y"
        pointLabelYOffset={-12}
        useMesh={true}
        enableGridX={false}
        enableGridY={false}
        // onClick={handleClick}
        tooltip={value => <Tooltip data={{val:value.point.data.y,name:value.point.serieId}} />}
        legends={[
            {
                onClick:()=> {},
                anchor: 'top',
                direction: 'row',
                justify: false,
                translateX: 0,
                translateY: 0,
                itemsSpacing: 14,
                itemDirection: 'left-to-right',
                itemWidth: 69,
                itemHeight: 0,
                itemOpacity: 0.75,
                symbolSize: 12,
                symbolShape: 'circle',
                symbolBorderColor: 'rgba(0, 0, 0, .5)',
                toggleSerie: false,
                effects: [
                    {
                        on: 'hover',
                        style: {
                            itemBackground: 'rgba(0, 0, 0, .03)',
                            itemOpacity: 1
                        }
                    }
                ],
                // onClick: (d) => {
                //     console.log(d);
                //     // d.hidden = !d.hidden
                //     // const colors = data.map(series => (series.id === d.id ? '#ff3344' : '#DDD'));
                //     // this.setState({
                //     //     colors: colors,
                //     // });
                // }
            }
        ]}/> 
    </>
        : null}
    </div>
    )

}

export default LineGraph