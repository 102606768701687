// import React from 'react';
import graphColors from '../data/graphColors';
import BoxChart from './BoxChart';
import LineGraph from './LineChart';
import moment from 'moment'
import makeCurveData from '../functions/makeCurveData'
import makeGraphDataSet from '../functions/makeGraphDataSet';
import RadialBar from './RadialBar';
import styled from 'styled-components';
import { device } from '../styled/device';
import { useState, useEffect } from 'react'
// import testdata from '../data/testgraph.json'

const labels = {
    "en": [
        "Learning",
        "Motivation",
        "Flow",
        "Growth",
        "Satisfaction"
    ],
    "da-DK": [
        "Læring",
        "Motivation",
        "Flow",
        "Growth",
        "Tilfredshed" //Tilfredshed
    ]
}


const RadialsContainer = styled.div`
    position: relative;
    width:${props => props.small ?  "80%" : "100%"};
    display:flex;
    flex-flow:wrap;
    height:${props => props.small ?  "80px" : "300px"};
    max-width:${props => props.small ? "100%" : "35rem"};
    @media ${device.mobileM} {
        max-width:100vw;
        /* height:${props => props.small ?  "60px" : "39vh"}; */
    }
    margin:0 auto;
    justify-content:space-evenly;
    /* justify-content:${props => !props.small ? "center" : "space-evenly"}; */

    @media ${device.mobileL} {
        width: 100%;
    }

`

function Graph({user,lang,small}) {

    // const {user, lang, small} = props

    const [selectedGraph, setselectedGraph] = useState();
    const [previewGraph, setPreviewGraph] = useState();
    const [graphsToShow, setGraphsToShow] = useState([])
    const [toolTip, setToolTip] = useState()

    const [data, setData] = useState()
    
    // console.log(user);
    let userAnswers
    if(user.attributes) {
        userAnswers = user.attributes.motivator_answers ? [...user.attributes.motivator_answers] : []
    } else {
        userAnswers = user.motivator_answers ? [...user.motivator_answers] : []
        
    }
    //DESELECT GRAPH WHEN LOOKING AT NEW USER
    useEffect(() => {
        setselectedGraph(null)
        // console.log(user);
        const newdata = Object.keys(results).map((key,index) => {
            const dataSet = makeGraphDataSet(labels[lang][parseInt(key)-1],results[key],graphColors[index]);
            return dataSet
        })
        setData(newdata)
    }, [user]);
    
    const results = {
        "1":makeCurveData(userAnswers,[2,3]),
        "2":makeCurveData(userAnswers,[1,2]),
        "3":makeCurveData(userAnswers,[1]),
        "4":makeCurveData(userAnswers,[2]),
        "5":userAnswers.map(answ => ({value:answ.rating*10,date:moment.utc(answ.date,"YYYY-MM-DD\THH:mm:ss\Z").format("DD-MM-YYYY")}))
    }

    const currentResult = Object.keys(results).map((id,index) => {
        // if(id === "trivsel") return
        // console.log(results[id])
        return ({
            id:labels[lang][index],
            data: [{
                x:"%",
                y:results[id][results[id].length - 1].value
            }],
            color:graphColors[index],
            trend: results[id].length > 1 ? results[id][results[id].length - 2].value : null

        })
    })

    currentResult.pop()


    const radials = currentResult.map((r,index) => {
        // console.log(userAnswers.length);
        return (
            <RadialBar 
                setToolTip={setToolTip}
                key={[r.id]}
                index={index}
                data={[r]} 
                hasGraph={userAnswers.length > 1} 
                small={small}
                graphsToShow={graphsToShow}
                setGraphsToShow={setGraphsToShow}
                select={setselectedGraph} 
                selectedGraph={selectedGraph}
                // previewGraph={setPreviewGraph}
            />
        )
    })

    useEffect(() => {

        
    }, []);



    // console.log(data);

    return (
        <div>
            <RadialsContainer small={small}>
                {toolTip}
                {radials}

            </RadialsContainer>
            {userAnswers.length > 1 && data ? 
                <LineGraph 
                    data={data}
                    graphsToShow={graphsToShow}
                    // selectedGraph={selectedGraph} 
                    // previewGraph={previewGraph}
                /> 
            : null}
        </div>
    );
}

export default Graph;