import React, {useState, useEffect} from "react"
import { CSVLink, CSVDownload } from "react-csv";
import RootContainer from "../styled/RootContainer"
import TextBox from '../styled/TextBox'
import putUser from '../functions/putUser.js'
import getAllusers from "../functions/getAllusers.js"
import { csv2json } from "csvjson-csv2json";
import { getCompanyId, putInvitedUsers, putNewTeam } from "../functions/strapiGet";


const AddUsers = () => {

    const [csvFile, setCsvFile] = useState("")
    const [uploadedFile, setUploadedFile] = useState({
        "teams":"",
        "users":""
    })
    const [inputText, setInputText] = useState({
        "teams":"",
        "users":""
    })
    const [message, setMessage] = useState(null)
    const [companyName, setCompanyName] = useState("");





    function handleChange(e) {

        // if(e.target.name === "csv") {
        //     setInputText(e.target.value)
        // }
        if(e.target.name === "company") {
            setCompanyName(e.target.value)
            return
        }
        const newState = {...inputText}

        newState[e.target.name] = e.target.value

        setInputText(newState)

    }
    async function handleSubmit(e) {
        e.preventDefault()
        console.log(companyName);

        if(inputText.teams) {
            const teamsArray = csv2json(inputText.teams);
            let companyID

            try {
                await getCompanyId(companyName)
                .then(res => companyID = res)
            } catch (error) {
                console.error(error);
            }
            // putNewTeam()
            teamsArray.forEach(team => {
                // console.log(team.teams);
                putNewTeam(team.teams,companyID)
            })

            setMessage("Done!")
                // console.log(csv2json(inputText.teams));
        }

        if(inputText.users) {
            putInvitedUsers(csv2json(inputText.users),companyName)
                .then(res => {
                    console.log(res);
                    setMessage(res)
                })
        }


        // PARSE STRING TO JSON, RETURN ERROR IF INVALID ELSE POST USER
        // try {
        //     // const parsedJSON = JSON.parse(inputText)
        //     const parsedJSON = csv2json(inputText)
        //     const promise = putUser(parsedJSON)
        //     promise.then(resp => {
        //         console.log(resp)
        //         if(resp.message) {
        //             setMessage(resp.message)

        //         } else {
        //             let messageString = `${resp.created.length} ${resp.created.length > 1 ? "users" : "user"} created`
        //             messageString = resp.notCreated.length > 0 ?  `${messageString} \n${resp.notCreated.length} ${resp.notCreated.length > 1 ? "emails" : "email"} already in use` : messageString;
        //             setMessage(messageString)

        //             // const allUsersPromise = getAllusers()
        //             // allUsersPromise.then(response => createCSV(response,resp.notCreated))
        //         }
        //     }).then(
        //         createCSV()
        //     )
        // } catch(err) {
        //     console.log(err)
        //     setMessage("Not a proper cvs")
            
        // }
        // const parsedJSON = await JSON.parse(inputText)

    }


        // console.log(csv)
        
    

    function handleUpload(e) {
        // e.preventDefault()
        console.log(e.target.files);
        const newState = {...uploadedFile}

        newState[e.target.name] = e.target.files[0]

        setUploadedFile(newState)
    }

    useEffect(()=> {
        // if(uploadedFile.users)

        const newState = {...inputText}

        Object.keys(uploadedFile).forEach(file => {
            const fileReader = new FileReader();
            if(uploadedFile[file]) {
                fileReader.onload = (e) => {
                    newState[file] = e.target.result
                    setInputText(newState)
                } 

                fileReader.readAsText(uploadedFile[file])
            }
        })
    },[uploadedFile])

    return(
        <RootContainer>
            <TextBox>
                <form onSubmit={handleSubmit}>
                    <input type="text" name="company" placeholder="company name" value={companyName} onChange={handleChange} required={true}/>
                    <textarea 
                        id="teams" 
                        name="teams" 
                        rows="8" 
                        cols="40" 
                        placeholder="paste TEAMS csv in here, or upload a .csv file...MAKE SURE THAT THE TEAMS HAVENT ALREADY BEEN CREATED"
                        onChange={handleChange}
                        value={inputText.teams}
                    />
                    <input
                        name="teams"
                        type={"file"}
                        accept={".csv"}
                        onChange={handleUpload}
                        // value={uploadedFile.teams}
                    />
                    <textarea 
                        id="users" 
                        name="users" 
                        rows="8" 
                        cols="40" 
                        placeholder="paste USERS csv in here, or upload a .csv file..."
                        onChange={handleChange}
                        value={inputText.users}
                    />
                      
                    <input
                        name="users"
                        type={"file"}
                        accept={".csv"}
                        onChange={handleUpload}
                        // value={uploadedFile.users}
                    />
                    <input style={{display:"block", margin:"20px 0"}} type="submit" value="Submit" />                           

                </form>
                <h1 style={{whiteSpace:"pre-wrap"}}>{message}</h1>
                {csvFile ? <CSVLink style={{textDecoration:"none", color:"white"}} data={csvFile}>Download CSV file</CSVLink>: null}
            </TextBox>
            
        </RootContainer>
    )
}

export default AddUsers