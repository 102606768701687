import TextBox from "../styled/TextBox";
import { LoginForm, InputBox } from '../styled/LoginForm';
import { SubmitButton } from "../styled/StyledSubmit";
import { Link, useParams , useNavigate } from 'react-router-dom';
import { useState, useContext, useEffect } from "react";
import { passwordRules } from "./Register";
import isStrongPassword from 'validator/lib/isStrongPassword'
import { StateContext } from "../data/stateContext";
import LanguageSwitcher from "../components/LanguageSwitcher";
import Logo from '../styled/Logo';
import { updatePassword } from "../functions/strapiGet";
import { ErrorBox } from "../styled/ErrorBox";


function ResetPAssword() {

    const { code } = useParams();

    const navigate = useNavigate()

    useEffect(() => {
        console.log(code);
        if(!code) {
            navigate('/')
        }
    }, [code]);

    const {locale, saveJwt } = useContext(StateContext)

    const [registerData, setRegisterData] = useState(
        {
            "password":"",
            "passwordConfirmation":""
        }
    );

    const localCopy = {
        "en":{
            password:"New Password",
            passwordConfirmation:"Repeat New Password",
            strongness:["Strong Password","Weak Password"],
            isSame:"Not identical"
        },
        "da-DK": {
            password:"Ny adgangskode",
            passwordConfirmation:"Gentag ny adgangskode",
            strongness:["Stærkt kodeord", "Svagt kodeord"],
            isSame:"Ikke ens"

        }
    }

    const [passwordCheck, setPasswordCheck] = useState("");
    const [strongness, setStrongness] = useState(null);
    const [isSame, setisSame] = useState(false);
    const [hasErrors, sethasErrors] = useState();

    const handleChange = (e) => {
        // console.dir(e.target.name);
        const inputData = {...registerData}
        if(e.target.name === "password") {
            // console.log(isStrongPassword(e.target.value, {...passwordRules, returnScore:false}));
            setStrongness(isStrongPassword(e.target.value, passwordRules))
        } else {
            e.target.dataset.touched = true;
        }
        inputData[e.target.name] = e.target.value
        setRegisterData(inputData)
    }
    
    const handleRepeat = (e) => {
        const inputData = {...registerData}
        const repeatedPass = e.target.value
        inputData.passwordConfirmation = repeatedPass
        setRegisterData(inputData)
        // e.target.dataset.touched = true;
        setPasswordCheck(repeatedPass)
    }
    useEffect(() => {
        if(passwordCheck === registerData.password && registerData.password !== "") {
            setisSame(true)
        } else {
            setisSame(false)
        }
    }, [passwordCheck,registerData.password]);

    const handleOnSubmit = (e) => {
        e.preventDefault();

        const dataLoad = {...registerData,"code":code}

        updatePassword(dataLoad)
            .then(json => {
                if(json.error) {
                    console.log(json.error.message);
                    
                    sethasErrors(<ErrorBox
                        message={"Invalid token - please request a new 'reset password' email"}
                        prompt={["Close"]}
                        cb={[()=>sethasErrors(null)]}
                    />)
                }
                if(json.jwt) {
                    saveJwt(json.jwt);
                    navigate('/')

                }
            })
        // console.log(dataLoad);
    }

    return (
        <>
            <Logo />
            <TextBox>
                <LoginForm id="reset" onSubmit={handleOnSubmit} isStrong={strongness} isSame={isSame}>
                    <div className="login">
                        <div className="text">
                            <p>{locale === "en" ? "Please fill in a new password for your account" : "Udfyld venligst en ny adgangskode"}</p>
                        </div>
                    </div>
                    <InputBox 
                        name='password' 
                        type="password" 
                        value={registerData.password} 
                        onChange={handleChange} 
                        placeholder={localCopy[locale].password}
                        required={true}
                        data-touched={passwordCheck !== ""}
                        valid={isSame}
                    />
                    <div className="strongness">
                        {strongness !== null ? strongness ? localCopy[locale].strongness[0] : localCopy[locale].strongness[1] : null}
                    </div>
                        {/* MAKE STRONGNESS COMPONENT <Strongness val={strongness}/> */}
                    <InputBox 
                        name='passwordConfirmation' 
                        type="password" 
                        value={passwordCheck} 
                        onChange={handleRepeat} 
                        placeholder={localCopy[locale].passwordConfirmation}
                        required={true}
                        data-touched={isSame}
                        valid={isSame}
                    />
                    <div className="issame">
                        {strongness !== null ? isSame ? null : localCopy[locale].isSame : null}
                    </div>
                    <SubmitButton 
                        style={{margin:"2rem 0"}}
                        type="submit"
                        disabled={!isSame}
                        active={isSame}
                    >
                        SEND
                    </SubmitButton>
                    <LanguageSwitcher pos={['bottom:3rem;','left:3rem;']} color={4}/>
                </LoginForm>
                
            </TextBox>
            {hasErrors}
        </>
    );
}

export default ResetPAssword;