import { useContext } from "react";
import { StateContext } from "../data/stateContext";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import logo from '../data/img/logo.png'
import { FiExternalLink, FiLogOut } from "react-icons/fi";
import { device } from "../styled/device";
import { SubmitButton } from "../styled/StyledSubmit";



const Navigator = styled.div`

    position: fixed;
    @media (max-width: 768px) {
        position:absolute;
        top:1rem;
        left:3rem;
        display:flex;
        justify-content:space-between;

        width:85vw;
        align-items:center;

        img {   
            opacity:0.75;
            margin:0;
        }
        h1 {
            align-self:center;
            margin:0 ;
        }

        & > * {
            margin-right: 2rem;
        }
        button {
            /* width:50%; */
            font-size:1rem;
            /* display: none; */
        }
        svg {
            display:none;
        }
    }
    top:3rem;
    left:3rem;
    img {
        width:15rem;
    }
    & > * {
        cursor:pointer;
        margin-bottom: 1em;
    }

    h1 {
        &:hover {
            text-decoration:underline;
        }
        svg {
            transform:translate(0,2px) ;
        }
    }
`

export default ({setProgress = null, progress, lang}) => {

    const {saveJwt,setUser, isManager, isAdmin} = useContext(StateContext)
    const navigate = useNavigate()

    const handleLogout = () => {
        saveJwt(null)
        setUser(null)
        navigate('/login')

    }
    const handleFeedback =(e) => { 
        const url = lang === "en" ? "https://paretify.com/en/motivatore/feedbackmotivator/" : "https://paretify.com/motivator/feedback-motivator/"
        window.location.href = url
    }

    
    return(
        <Navigator>
            <img src={logo} alt="Paretify Logo" onClick={()=>{if(setProgress){setProgress(-1)} else {navigate('/')}}}></img>
            <h1 onClick={handleLogout}><FiLogOut/> Logout</h1>
            {/* <h1 onClick={()=>navigate("/")}><FiExternalLink /> Home</h1> */}
            {isManager || isAdmin ? 
                <h1 onClick={()=>navigate("/dashboard")}><FiExternalLink /> Dashboard</h1>
            : null
            }
            {/* <h1 onClick={()=>navigate("/manage_users")}>link</h1> */}
            <SubmitButton onClick={handleFeedback} active={progress > 6} style={{margin:"1rem 0"}}>Send FeedBack</SubmitButton>
        </Navigator>
    )
}