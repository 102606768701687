import React from 'react';
import { GoDash } from "react-icons/go";

// import {  } from "react-icons/bs";

import styled from 'styled-components';
import colors from '../data/colors';
import { FaArrowRight } from "react-icons/fa";
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import SentimentDissatisfiedIcon from '@mui/icons-material/SentimentDissatisfied';
import SentimentSatisfiedIcon from '@mui/icons-material/SentimentSatisfied';
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAltOutlined';
import SentimentVerySatisfiedIcon from '@mui/icons-material/SentimentVerySatisfied';

const styleIcons = {width:"2.5rem",height:"2.5rem"}
const customIcons = [
    <SentimentVeryDissatisfiedIcon style={styleIcons} />,
    <SentimentDissatisfiedIcon style={styleIcons} />,
    <SentimentSatisfiedIcon style={styleIcons} />,
    <SentimentSatisfiedAltIcon style={styleIcons} />,
    <SentimentVerySatisfiedIcon style={styleIcons} />
];



const Container = styled.div`
    display:flex;
    flex-direction:row;
    width:15%;
    justify-content:space-around;
    align-items: center;
    i.trend svg{
        color: ${props => props.color};
        transform:${props => `rotate(${props.degrees}deg)`};
    }
    i {
        /* transform:rotate(90deg); */
        display: flex;
        justify-content: center ;

    }
    span.val {
        /* margin-right:1rem; */
        font-size:1.5rem ;
        width:2rem;
    }
    svg.MuiSvgIcon-root {
        color:${props => props.active ? colors[4] : colors[1]};
    }

`

function TrivselTrend(props) {

    const {active, value, user} = props
    // console.log(user);
    const answers = user.attributes.motivator_answers
    const clamp = (num, min, max) => Math.min(Math.max(num, min), max);
    let trendFactor = 0

    if(answers.length > 1) {
        trendFactor = answers[answers.length - 2].rating - value;
    }
    
    const degress = clamp((trendFactor * 18),-90,90);

    // console.log(trendFactor);

    return (
        <Container active={active} degrees={degress} color={trendFactor < 0 ? "#6bd583" : trendFactor === 0 ? colors[2] : colors[5]}>
            {customIcons[Math.round((value/2)-1)]}
            <span className="val">{value}</span>
            <i className="trend">{trendFactor === 0 ?<GoDash size={20}/> : <FaArrowRight size={20}/>}</i>
        </Container>
    )
    
    
}

export default TrivselTrend;