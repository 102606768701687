import React from 'react';
import styled from 'styled-components';
import Graph from './Graph';
import { BiError } from "react-icons/bi";
import { device } from '../styled/device';

const GraphsContainer = styled.div`
    /* height:300px; */
    width:80vw;
    max-width:60rem;
    margin:0 auto;

    @media ${device.mobileL} {
        width:100%;
        height:50vh;
        overflow:scroll;
    }

    //dev
    #NA {
        height:30vh;
        @media ${device.mobileM} {
            height:50vh;
        }
        display:flex;
        flex-direction:column;
        justify-content:center;
        align-items:center ;
    }
`

function DashboardGraphs(props) {

    const {allUsers, selectIndex, lang} = props
    const currentUser = allUsers[selectIndex]
    const userAnswers = currentUser.attributes.motivator_answers ? [...currentUser.attributes.motivator_answers] : []
    // console.log(currentUser);

    return (
        <GraphsContainer style={{...props.style}}>
            {userAnswers.length > 0 ?
            <Graph 
                lang={lang} 
                user={allUsers[selectIndex]} 
                small={userAnswers.length > 1}
            />
            :
            <div id="NA">
                <BiError size={30}/>
                <h1>NO DATA</h1>
            </div>
            }

        </GraphsContainer>
    );
}

export default DashboardGraphs;