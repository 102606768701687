import React, { useState, useEffect, useContext} from 'react';
import styled from 'styled-components';
import RootContainer from "../styled/RootContainer"
import {Link, useParams} from "react-router-dom"
// import getOneCompany from '../functions/getOneCompany';
import Teams from '../components/Teams';
import Users from '../components/Users';
import colors from '../data/colors';
// import updateTeams from '../functions/updateTeams';
// import addTeam from '../functions/addTeam';

// import putUser from '../functions/putUser';
import updateCompanyMembers from '../functions/updateCompanyMembers'
import deleteUser from '../functions/deleteUser';
import deleteTeam from '../functions/deleteTeam';
import editRole from '../functions/editRole';
import CompanyTitle from '../components/CompanyTitle';
import { device } from '../styled/device';
import { StateContext } from '../data/stateContext';
import { ErrorBox } from '../styled/ErrorBox';

import { getAllUsersFromCompany, getAllTeamMembers, putUserTeamRelation, getTeamID, putNewTeam } from '../functions/strapiGet'; 

const DashBoardContainer = styled(RootContainer)`
    width:90vw;
    max-width:90rem;
    transition:filter 0.2s linear;
    filter: ${props => props.error ? "blur(10px)" : "blur(0)"};
    
    & > div {
        display:flex;
        
        @media ${device.mobileL} {
            flex-direction: column;
        }        
        /* @media ${device.laptop} {
            flex-direction: row;
        } */
        
        .manageBox {

            @media ${device.mobileL} {
                width:calc(100% - 6rem);
                height:24vh;
            }
            @media ${device.laptop} {
            }
            width:33%;
            height:80vh;
            overflow:scroll;
            background-color: ${colors[3]};
            margin:1rem;
            padding:2rem;
            border-radius:5px;
            
            .clickable {
                cursor: pointer;
                &:active {
                    transform:scale(0.96);
                }
            }
        }
    }
`

// const Error = styled.div`
//     position: fixed;
//     top:50%;left:50%;
//     transform:translate(-50%,-50%);
//     width:33vw;
//     height:20vh;
//     background-color:${colors[3]};
//     border-radius:15px;

//     & > div {
//         position:relative;
//         height:100%;width:100%;
//         display:flex;
//         justify-content:center;
//         align-items:center;
        
//         #button {
//             display:flex;
//             justify-content:center;
//             align-items:center;
//             position:absolute;
//             bottom:2rem;
//             left:50%;
//             transform:translate(-50%,0);
//             width:5rem;
//             height:1rem;
//             & > div {
//                 background-color: ${colors[2]};
//                 padding:0.5rem 1rem;
//                 margin:0.5rem;
//                 cursor:pointer;

//             }
//         }
//     }
// `
// function ErrorComp({message,prompt,cb}) {

//     const buttonPrompts = prompt.map((str,index)=> 
//         <div key={index} onClick={cb[index]}><p>{str}</p></div> 
//     )
//     return (            
//     <Error>
//         <div>
//             <div id="icon"><BiError size={30}/></div>
//             <h1>{message}</h1>
//             <div id="button">
//                 {buttonPrompts}
//             </div>
//         </div>
//     </Error> );
// }





function Management() {
    // const [links, setLinks] = useState();
    // const { company } = useParams();
    const [companyID, setCompanyID] = useState();
    const [companyMetaData, setMetaCompanyData] = useState();
    const [selectedTeamName, setselectedTeamName] = useState();
    const [selectedTeamObj, setselectedTeamObj] = useState();
    const [teamRooster, setTeamRooster] = useState();
    const [error, setError] = useState(null);
    const [teams, setTeams] = useState()
    const [members, setMembers] = useState()
    
    const [hasFetched, sethasFetched] = useState(false);
    const [allUsers, setAllUsers] = useState([]);
    // const [teamList, setTeamList] = useState([]);    
    const [teamArray, setTeamArray] = useState();

    const {user, jwt} = useContext(StateContext)

    useEffect(() => {
        
        if(user) {
            getCompanyData();
        }

    }, [user]);



    const getCompanyData = () => {
        setCompanyID(user.company.id)
        // console.log("getCOmpanyData")
        getAllUsersFromCompany(user.company.id)
        .then(res => {
            if(res.error) {
                console.error(res.error);
                // sethasErrors(res.error.message)
                return
            }
            // console.log(res);
            setMetaCompanyData({
                members:res.employees.data.length,
                teams:res.teams.data.length,
                name:res.name})
            setAllUsers([...res.employees.data])

            setTeamArray([...res.teams.data])
            // console.log(res.teams.data);
            sethasFetched(true)
        })
    }

    // useEffect(() => {
    //     console.log("all users updated")
    // }, [allUsers]);

    useEffect(() => {
        if(teamArray) {
            // console.log(teamList);
            // console.log(allUsers);
            getAllTeamMembers(teamArray, allUsers)
            .then(res => {
                // console.log(res);
                // setTeamList(res)
                setTeams(res)

                let allTeams = {}
                res.forEach(t => {
                    const userObjArr = t.members.map(m => {
                        return {"id":m.id, ...m.attributes}
                    })
                    allTeams[t.name] = [...userObjArr]
                })
                setTeamRooster(allTeams)
                // console.log(res);

            })
        }
    }, [teamArray]);
    
    function updateTeamRooster(team, inWhatTeam ,user) {
        const updatedTeamRooster = {...teamRooster}
        // console.log(user);

        if(inWhatTeam) {
            
            const removed = updatedTeamRooster[inWhatTeam].filter(m => m.id !== user.id)
            updatedTeamRooster[inWhatTeam] = removed
    
            // console.log(updatedTeamRooster);
            setTeamRooster(updatedTeamRooster)
            updateUserTeam(user,null)
        } else {
            // console.log('add to team');
            const addUser = {id:user.id, ...user.attributes}
            updatedTeamRooster[team].push(addUser)
            updateUserTeam(user,team)
        }
        
    }

    const updateUserTeam = (user, team) => {
        
        putUserTeamRelation(user, team)
        .then(res => {
            getCompanyData()
        })
        if(team) {
        }
    }


    function handleAddTeam(team) {
        // setTeamRooster(null)
        // console.log(companyID);

        putNewTeam(team[0].name, companyID, jwt).then(res => {
            getCompanyData()
        })

    }

    function handleDeleteUser(id) {
        // console.log(id);

        const yes = (id)=> {
            const updatedCompanyData = deleteUser(id)
            updatedCompanyData.then(data => {
                if(data.message) {
                    setError(<ErrorBox 
                        message={data.message} 
                        prompt={["close"]} 
                        cb={[()=>setError(null)]}
                    />)
                    const updatedCompanyData = updateCompanyMembers(companyID)
                    updatedCompanyData.then(newdata => {
                        setTeamRooster(null)
                        setMetaCompanyData(newdata)
                    })
                }

            })

        }

        setError(
            <ErrorBox 
                message={`You sure you want to delete?`} 
                prompt={["YES","NO"]} 
                cb={[()=>yes(id),()=>setError(null)]}
            />
        )
    }
    function handleDeleteTeam(team) {

        

        setselectedTeamName(null)
        const yes = (t) => {
            // console.log(t);
            const updatedCompanyData = deleteTeam(companyID,t)
            updatedCompanyData.then(data => {
                if(data.message) {
                    setError(<ErrorBox 
                        message={data.message} 
                        prompt={["close"]} 
                        cb={[()=>setError(null)]}
                    />)
                } else {
                    setTeamRooster(null)
                    setMetaCompanyData(data)
                    setError(null)
                    
                }
            })

        }
        setError(
            <ErrorBox 
                message={`You sure you want to delete "${team.name}?"`} 
                prompt={["YES","NO"]} 
                cb={[()=>yes(team.name),()=>setError(null)]}
            />
        )
    }

    function handleEditRole(id,role) {
        // console.log(id,role);
        const updatedCompanyData = editRole(id,role)
        updatedCompanyData.then(data => {
            if(data.error) {
                setError(<ErrorBox 
                    message={data.message} 
                    prompt={["close"]} 
                    cb={[()=>setError(null)]}
                />)
            } else {
                const updatedCompanyData = updateCompanyMembers(companyID)
                updatedCompanyData.then(newdata => {
                    setTeamRooster(null)
                    setMetaCompanyData(newdata)
                })
            }
        })

    }


    // useEffect(() => {
    //     console.log(selectedTeamName)
    // }, [selectedTeamName]);
    
    // const userProps = {
    //     members:members,
    //     selectMode:selectedTeamName ? true : false,
    //     selectedTeamName:selectedTeamName,
    //     add:updateTeamRooster,
    //     teamRooster:teamRooster,
    //     deleteUser:handleDeleteUser,
    //     editRole:handleEditRole
    // }

    return (
        <>
           
            <DashBoardContainer error={error !== null}>
            {teamRooster ? 
                <>
                    <CompanyTitle data={companyMetaData}/>
                    <div>
                        <Users 
                            mode={"leder"}
                            title={"Managers"}
                            // functions={{...userProps}}
                            members={members} 
                            selectMode={selectedTeamName ? true : false}
                            selectedTeamName={selectedTeamName}
                            add={updateTeamRooster}
                            teamRooster={teamRooster}
                            // handleAddUser={handleAddUser}
                            deleteUser={handleDeleteUser}
                            editRole={handleEditRole}
                        />
                        <Teams 
                            selectedTeamName={selectedTeamName} 
                            select={setselectedTeamName}
                            teams={teams}
                            handleAddTeam={handleAddTeam}
                            deleteTeam ={handleDeleteTeam}
                        />
                        <Users 
                            mode={"employee"}
                            title={"Users"}
                            // functions={{...userProps}}
                            members={allUsers} 
                            selectMode={selectedTeamName ? true : false}
                            selectedTeamName={selectedTeamName}
                            add={updateTeamRooster}
                            teamRooster={teamRooster}
                            // handleAddUser={handleAddUser}
                            deleteUser={handleDeleteUser}
                            editRole={handleEditRole}
                        />
                    
                    </div>
                </>
            : null}
            </DashBoardContainer>
            {error}
        </>
    );
}

export default Management;