import React, { useContext } from 'react';
import styled from 'styled-components';
import colors from '../data/colors.js';
import { StateContext } from '../data/stateContext.js';

import texts from '../data/texts.js'
import parse from 'html-react-parser';
import TextBox from '../styled/TextBox'
import LanguageSwitcher from './LanguageSwitcher.js';
import { SubmitButton } from '../styled/StyledSubmit.js';
import Logout from './Logout.js';

// const SubmitButton = styled.button`
//     /* position: fixed; */
//     /* bottom:10rem;
//     left:50%; */
//     padding: ${props => props.active ? "1rem": "0rem"};
//     background-color:${colors[5]};
//     border:none;
//     border-radius: 5px;
//     margin:5rem auto;
//     overflow: hidden ;
//     height:${props => props.active ? "3em" : "0"};
//     color:white;
//     display: block ;
//     /* display:${props => props.active ? "block" : "none"} ; */
//     transition: padding 0.4s ease-in-out, height 0.4s ease-in-out;
//     cursor:pointer;
//     &:active {
//         transform:scale(0.9) ;
//     }
//     &:hover {
//         background-color:${colors[2]} ;
//     }
// `

function IntroText(props) {

    const {allCopy, user, locale} = useContext(StateContext)

    const {lang, readyToAnswer} = props
    let index = user ? user !== "no user" ? 1 : 2 : 0;
    if(!readyToAnswer) {
        index = 3
    }

     return ( 
        <TextBox>
            <React.Fragment>
                <h1>{locale === "en" ? "Hi" : "Hej"} {user ? `${user.firstName} ${user.lastName}` : null}</h1>
                <br></br>
                <div style={{whiteSpace:"break-spaces",marginTop:"1em"}}>
                    {!readyToAnswer ? allCopy.intro_locked : allCopy.intro}
                    {/* {texts[lang][index]} */}
                </div>
                <SubmitButton onClick={()=> props.setProgress(readyToAnswer ? 0 : 7)} active={true}>{locale === "en" ? "NEXT" : "VIDERE"} </SubmitButton>
                <LanguageSwitcher pos={['bottom:3rem;','left:3rem;']} color={4}/>
            </React.Fragment>
            {/* <Logout style={{right:"2rem"}}/> */}

        </TextBox>
    );
}

export default IntroText;