import React, { useState, useEffect } from 'react';
import { BsFillPersonFill } from "react-icons/bs";
import { MdUpgrade } from "react-icons/md";
import { BsFillPersonXFill } from "react-icons/bs";
import { BsPersonPlusFill } from "react-icons/bs";
import { IoSettingsOutline } from "react-icons/io5";
import AddToCompany from './AddToCompany';
import copyText from '../functions/copyText';



import teamColors from '../data/teamColors';
import CardStyled from '../styled/CardStyled';

function Card({inWhatTeam,mode,editRole,user,team,deleteUser, selectMode,add,highlight,color}) {

    const [inSettings, setinSettings] = useState(false);
    const [loaded, setloaded] = useState("")
    const [showInfo, setshowInfo] = useState("");
    
    const invertedRole = mode === "leder" ? "employee" : "leder"

    useEffect(() => {
        setTimeout(()=>{
            setloaded("loaded")
        },20)
        // console.log(inWhatTeam);
    }, []);

    function handleClick(e) {
        if(selectMode) {
            console.dir(team);
            add(team,inWhatTeam, user)
        }
        if(inSettings && e.target.tagName !== "SVG") {
            setinSettings(false)
        }
    }

    function handleClickSettings() {
        setinSettings(!inSettings)
    }

    function handleDeleteClick() {
        deleteUser(user.id);
        
    }

    function showProfile() {
        // console.log(user.id,user.name,user.role);
        if(!selectMode) {
            setshowInfo(showInfo === "info" ? "" : "info")
        }

    }
    const {attributes:{firstName,lastName,email}} = user
    return (
        <CardStyled 
            className={`${showInfo} ${loaded} ${inSettings ? "inSetting" : ""} card user ${selectMode ? "select" : ''} ${selectMode && !highlight ? 'muted' : ''}`}
            onClick={handleClick}
            style={selectMode ? {backgroundColor:color} : null}
        >
            <span className='dot fixed' style={{backgroundColor:color}}></span>
            <div className='profil' onClick={showProfile}>
                <BsFillPersonFill size={20}/>
                <h2>{`${firstName} ${lastName}`}</h2>
            </div>
            {/* <div class="functions">
                <p>select team</p>
            </div> */}
            <div className="gear" onClick={handleClickSettings}>
                <IoSettingsOutline size={15}/>
            </div>
            <div className='settings'>
                <span className={mode}><MdUpgrade size={20} onClick={() => editRole(user.id,invertedRole)}/></span>
                <span><BsFillPersonXFill size={20} onClick={handleDeleteClick}/></span>
            </div>
            <div class="info">
                <p className="link">Email: <br /> <i onClick={copyText}>{email}</i></p>
                {/* <p className="link">Personal Link: <br /> <i onClick={copyText}>{`${process.env.REACT_APP_BASE_URL}/${user.id}`}</i></p> */}
            </div>
        </CardStyled>
    );
}

function Users({mode,title, editRole,deleteUser,teamRooster,members,selectMode, selectedTeamName,add = [{members:[]}]}) {

    // const currentTeam =
    // console.log(teamObj);
    const [cards, setCards] = useState();

const [adding, setadding] = useState(false);

    useEffect(() => {
        // console.log(members);
        if(members) {
            // console.log(teamRooster);
            makeComps()
        }
    }, [members, selectMode,selectedTeamName, teamRooster]);

    function makeComps() {
        const comps = members.map((user,index) => {
            // console.log(user);
            let userInTeam = false;
            let userTeamIndex = 0;
            let isHighLighted = false
            let inWhatTeam
            Object.keys(teamRooster).forEach((teamName,index)=> {
                // console.log(teamName);

                const inTeam = teamRooster[teamName].filter(t => t.id === user.id)
                
                if(inTeam.length > 0) {
                    userInTeam = true
                    inWhatTeam = teamName
                    userTeamIndex = index
                    isHighLighted = teamName === selectedTeamName
                    // console.log(isHighLighted);
                }
            })
            if(user) {
            // if(user.role === mode) {
                return <Card 
                    mode={mode}
                    deleteUser={deleteUser}
                    key={index}
                    user={user}
                    inWhatTeam={inWhatTeam}
                    team={selectedTeamName}
                    selectMode={selectMode} 
                    add={add}
                    color={userInTeam ? teamColors[userTeamIndex] : ""}
                    highlight={selectMode && isHighLighted}
                    editRole={editRole}
                />
            }
        })
        setCards(comps)
    }
    return ( 
        <div className='manageBox'>
            <h1>{title}</h1>
            {cards}            
            {/* {adding ? 
            <AddToCompany mode={"user"} handleAdd={handleAddUser} setAddState={setadding} />
            : null}
            <CardStyled className="add loaded" onClick={()=>setadding(!adding)}>
                {adding ?
                    <h1>CANCEL</h1>
                :
                <BsPersonPlusFill size={40}/>}

            </CardStyled> */}
        </div> 
    );
}

export default Users;