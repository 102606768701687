import React from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import GlobalStyle from './styled/GlobalStyles';
import AddUsers from './routes/AddUsers'
import LederDashboard from './routes/LederDashboard';
import Management from './routes/Management';
import Login from './routes/Login'
import StateContextProvider from './data/stateContext';
import Register from './routes/Register';
import ResetPAssword from './routes/ResetPassword';
import ForgtPassword from './routes/forgot-password';



const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <StateContextProvider>
      <GlobalStyle />
      <Router>
        <Routes>
          <Route path='/' exact element={<App />} />
          <Route path='/login' element={<Login />} />
          <Route path='/register/:company' element={<Register />} />
          <Route path='/register/' element={<Register />} />
          <Route path='/forgot-password' element={<ForgtPassword />} />
          <Route path='/reset-password/:code' element={<ResetPAssword />} />
          <Route path='/:userId' element={<App />} />
          <Route path='/addusers' element={<AddUsers />} />
          <Route path='/dashboard/' element={<LederDashboard />} />
          <Route path='/dashboard/:company' element={<LederDashboard />} />
          <Route path='/manage_users' element={<Management />} />
          <Route path='/manage_users/:company' element={<Management />} />
        </Routes>

      </Router>
    </StateContextProvider>

  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
