import {useState, useEffect, useContext} from 'react';
import { ResponsiveRadialBar } from '@nivo/radial-bar'
import styled from 'styled-components';
import { GoArrowUp } from "react-icons/go";
import colors from '../data/colors';
import { StateContext } from '../data/stateContext';
import { BsInfoLg } from "react-icons/bs";

const StyledToolTip = styled.div`
    position:absolute;
    top:-10rem;
    left:50%;
    width:100%;
    height:auto;
    padding:1rem;
    background-color:${colors[3]};
    transform:translate(-50%, -25%);
    z-index:700;
    border-radius:5px;
    
    span {
        
        font-size:1.2rem;
    }
    color:black;
    #symbol {
        position: relative;
        svg {
            position: absolute;
            top:0rem;
            right:0.5rem;
        }
    }

`

const Tooltip = ({data,text}) => {

    return (
        <StyledToolTip>
                <div id="symbol">
                    <BsInfoLg color={"black"} size={"2rem"}/>
                </div>
                {/* <p>{data.bar.groupId}</p> */}
                <p>{text}</p>
        </StyledToolTip>
    )
}

// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.
// website examples showcase many properties,
// you'll often use just a few of them.

const RadialContainer = styled.div`
    width:${props => props.small ? "20%" : "40%" };
    height:${props => props.small ? "100px" : "150px" };
    position:relative;
    border:${props => props.isSelected ? "1px solid white" : "none"};
    border-radius:20px;
    box-sizing: border-box;
    margin:1rem;
    h1, h2,h3 {
        position:absolute;
    }
    h1 {
        font-size:${props => props.small ? "1.2rem" : "1.2rem" };
        top:0;
        left:50%;
        transform:translate(-50%,0%);
    }
    h2 {
        top:50%;
        left:50%;
        transform:translate(-50%,0%);
        font-size:${props => props.small ? "1.2rem" : "2rem" };
    }
    cursor: pointer;
    &:hover {
        border:${props => props.hasGraph ? props.isSelected ? "1px solid white" : "1px solid rgba(255,255,255,0.4)" : "none"};
        /* transform: ${props => props.hasGraph ? "scale(1.1)" : ""} ; */
    }
`
const TrendStyled = styled.h3`
    top:75%;
    left:50%;
    /* left:${props => props.small ? "0" : "50%"}; */
    transform:translate(-60%,-50%) ${props => props.small ? "scale(0.9)": ""};
    font-size:1.2rem;
    color:${props => props.val <= 0 ? colors[5] : colors[6]};
    span {
        display:inline-block;
        /* transform-origin: center bottom; */
        transform:${props => props.val <= 0 ? "rotate(180deg)" : "rotate(0deg)"};
    }
`

export default ({setToolTip,graphsToShow, setGraphsToShow,index, select, data , small, selectedGraph, hasGraph}) => {
    const trend = data[0].data[0].y - data[0].trend
    // const dataNew = {}
    // dataNew.id = data[0].label
    // dataNew.data = [{
    //     x:"%",
    //     y:data[0]["1"]
    // }]
    // const dataNewArr = [dataNew]
    const [isSelected, setisSelected] = useState(false);
    const [popUpText, setPopUpText] = useState()

    const indexToText = ["learning","motivation","flow","growth"]


    const {allCopy} = useContext(StateContext)

    useEffect(() => {
        const copy = allCopy[`${indexToText[index]}_popup`]
        setPopUpText(copy)
    }, [allCopy]);
    // console.log(data[0].id, index);
    useEffect(() => {
        
        if(graphsToShow.includes(index)) {
            setisSelected(true)
        }
        if(isSelected) {
            deleteFromArr()
            setisSelected(false)
        }


    }, [selectedGraph]);
    
    const handleClick = (e) => {
        if(hasGraph) {
            // console.log(index);
            if(isSelected) {
                deleteFromArr()
                // select()
                setisSelected(false)
            } else {
                select(index)
                // select(data[0].id)
            }
        }
    }
    const handleMouseLeave = (e) => {
        setToolTip(null)
        if(hasGraph) {
            // console.log("LEAVE");
            // previewGraph("delete")
            if(!isSelected) {
                deleteFromArr()
            }
        }
    }
    const handleMouseEnter = (e) => {
        setToolTip(<Tooltip text={popUpText} />)
        if(graphsToShow.includes(index)) return;
        if(hasGraph && !isSelected) {
            setGraphsToShow([...graphsToShow,index])
            // console.log(e);
            // previewGraph(data[0].id)
        }
    }

    function deleteFromArr() {
        const newGraphsToShow = [...graphsToShow]
        const findIndex = graphsToShow.indexOf(index);
        if (findIndex > -1) { // only splice array when item is found
            newGraphsToShow.splice(findIndex, 1); // 2nd parameter means remove one item only
        }
        setGraphsToShow(newGraphsToShow)
    }

    return (
        <RadialContainer 
                small={small} 
                onClick={handleClick} 
                onMouseEnter={handleMouseEnter} 
                isSelected={isSelected} 
                hasGraph={hasGraph}
                onMouseLeave={handleMouseLeave} 
        >
            <h1>{data[0].id}</h1>
            <h2>{data[0].data[0].y}%</h2>
            {data[0].trend !== null ? 
                <TrendStyled small={small} val={trend}><span><GoArrowUp size={20}/></span>{trend}</TrendStyled>
            : null
            }
            <ResponsiveRadialBar
                data={data}
                colors={data.map((d) => d.color)}
                enableRadialGrid={false}
                enableCircularGrid={false}
                valueFormat=">-.2f"
                startAngle={-132}
                endAngle={132}
                innerRadius={0.50}
                padding={0.25}
                cornerRadius={10}
                maxValue={100}
                margin={{ top: 15, right: 0, bottom: 0, left: 0 }}
                radialAxisStart={null}
                circularAxisOuter={null}
                legends={[]}
                tooltip={value => {}}
            />
            
        </RadialContainer>
    )
}