import { LocalCafe } from '@mui/icons-material';
import React, { useState, useEffect, useRef, useContext } from 'react';
import styled from 'styled-components';
import UserBar from '../components/UserBar';
import colors from '../data/colors';
import { StateContext } from '../data/stateContext';
import { device } from '../styled/device';

const Legend = styled.div`

        @media ${device.mobileM} {
            width:100%;
            max-width:100vw;
        }
        @media ${device.laptop} {
            width:100%;
            max-width:50rem;
        }
        /* height:60vh; */
        overflow:auto;
        /* margin:0 auto; */
        display:flex;
        justify-content:space-between;

        p {
            padding:0.5rem;
            text-align:center;
            font-size: 1rem;
            color:${colors[2]}
            }

`
const UserBarContainer = styled.div`
        height:calc(100vh - 350px);
        overflow:auto;
        /* margin:0 auto; */

        @media ${device.mobileM} {
            width:100%;
            max-width:100vw;
            height:calc(50vh - 2.5rem);
        }
        
        @media ${device.laptop} {
            width:100%;
            max-width:50rem;
        }


        #legend {

        }
        
        .bar {
            transition: all 0.2s linear, transform 0.1ms linear ;
            border:1px solid ${colors[3]};
            box-sizing:border ;
            padding:1rem;
            border-radius:5px;
            margin:0.5rem 0;
            display:flex;
            align-items:center;
            background-color: ${colors[3]};
            color:black;
            cursor:pointer;
            &:active {
                transform:scale(0.97) ;
            }
            &:hover {
                background-color: ${colors[2]}
            }
            
            &.active {
                &:hover {
                    background-color: ${colors[1]}
                }
                background-color: ${colors[1]};
                /* border:1px solid ${colors[1]}; */
                /* border: 2px solid black; */
                color:white;
            }
            
            span {
                text-align:center;
                font-size:1.2rem;
                /* width: 50%;
                align-self: center; */
            }

            & > span {
                width:40%;
            }
            svg {
                margin:0 auto;
                color: ${colors[4]}
            }

            .profil {
                display:flex;
                flex-direction:column;
            }
            .status {
                width:25%;
                display:flex;
                justify-content:flex-end;
                align-items:center;
                p {
                    width:75%;
                    font-size:1rem;
                    text-align:right;
                }
                & > div {
                    width:25%;
                }
            }
        }
`


function UserBars(props) {
    const [userBarsComps, setuserBarsComps] = useState();
    // const [currentIndex, setCurrentIndex] = useState(0);

    const {resultsCopy, locale} = useContext(StateContext)

    const {selectedTeam,allUsers, hasFetched, setIndex, selectIndex, jwt} = props

    const handleClick = (index) => {
        setIndex(index)
        // setCurrentIndex(index)
    }
    // console.log(allUsers);

    const ref = useRef()


    useEffect(() => {
        if(resultsCopy) {

            const resultObj = {}

            resultsCopy.forEach(c => {
                resultObj[c.name] = c.title
            })
            const comps = allUsers.map((user,index) => {
                return (
                    <UserBar
                        selectedTeam={selectedTeam}
                        lang={locale}
                        key={index} 
                        index={index} 
                        user={user}
                        resultObj={resultObj}
                        handleClick={handleClick}
                        jwt={jwt}
                        active={index === selectIndex}
                    />
                )
            })
            setuserBarsComps(comps)

        }
        // if(hasFetched) {
        // }
    }, [allUsers, selectIndex,resultsCopy]);

    // useEffect(() => {
    //     console.log(locale)
    // }, [locale]);

    // useEffect(() => {
    //     window.addEventListener("keydown", keydownHandler)
    //     // return  () => {
    //     //     window.removeEventListener("keydown",keydownHandler)
    //     // }
    // }, []);

    // const keydownHandler = (e) => {
    //     // console.log(currentIndex)
    //     let direction = e.key === "ArrowUp" ? -1 : e.key === "ArrowDown" ? 1 : 0;
    //     setIndex(selectIndex + direction)
    //     // setCurrentIndex(currentIndex + direction)
    // }

    const handleKeyDown = (e) => {
        console.log(e);
    }
    return (
        <div id="userbars" style={{...props.style}}>
            <Legend id="legend">
                <p style={{width:"22%"}}>{locale === "en" ? "Name": "Navn"}</p>
                <p style={{width:"32%"}}>{locale === "en" ? "Result": "Resultat"}</p>
                <p style={{width:"17%"}}>{locale === "en" ? "Satisfaction": "Tilfredshed"}</p>
                <p style={{width:"23%"}}>Status</p>
            </Legend>
            <UserBarContainer onKeyDown={ handleKeyDown } ref={ref}>
                {userBarsComps}
            </UserBarContainer>
        </div>
    );
}

export default UserBars;