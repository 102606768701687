import React, { useEffect, useRef, useState } from 'react';
import { BsFillPersonFill } from "react-icons/bs";
import { getUserTeamRelation } from '../functions/strapiGet';
import Status from './Status';

import TrivselTrend from './TrivselTrend';



function UserBar({user, index, handleClick, active, resultObj, selectedTeam, jwt}) {

    const [period, setPeriod] = useState();

    // const {user, index, handleClick, active, resultObj} = props
    const ref = useRef()
    // console.log(user.id);
    const userAnswers = user.attributes.motivator_answers ? [...user.attributes.motivator_answers] : []
    // console.log(resultsCopy);
    // useEffect(() => {
    //     sethasContent(user.attributes.motivator_answers.length > 0)
    //     console.log(user.name);
    // }, []);

    useEffect(() => {
        if(user) {
            getUserTeamRelation(user.id, jwt)
                .then(res => setPeriod(res.period))
        }
    }, [user]);

    const hasContent = userAnswers.length > 0
    const lastAnswer = userAnswers.length - 1

    const userName =  [user.attributes.firstName,user.attributes.lastName];
    // console.log(user);
    // const userName =  user.attributes.username.trim().split(/\s+/);

    useEffect(() => {
        if(active) {
            ref.current.scrollIntoView({ block: 'start',  behavior: 'smooth' })
        }
    }, [active]);

    useEffect(() => {
        if(selectedTeam) {
            // getTeamPeriod(selectedTeam)

        }
    }, [selectedTeam]);

    // useEffect(() => {
    //     const lastAnswer =
    // }, []);


    // console.log(`${userAnswers[lastAnswer].result}_${lang}`); 
    return (
        
        <div ref={ref} className={`bar ${active ? 'active' : ""}`} onClick={() => handleClick(index)}>
            <div className="profil" style={{width:"20%"}}>
                <BsFillPersonFill size={40}/>
                {userName.map((name,index) => <span key={index}>{name}</span>)}
            </div> 
            {hasContent ? 
                <React.Fragment>
                    <span>"{resultObj[userAnswers[lastAnswer].result]}"</span> 
                    <TrivselTrend active={active} value={userAnswers[lastAnswer].rating} user={user} />
                    {period ? <Status answer={userAnswers[lastAnswer]} period={period}/> : null}
                </React.Fragment>
            : 
                <span>"N/A"</span>
            }
        </div>

    );
}

export default UserBar;