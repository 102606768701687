import { useState, useContext, useEffect } from 'react'
import TextBox from '../styled/TextBox'
import { LoginForm, InputBox } from '../styled/LoginForm';
import { SubmitButton } from "../styled/StyledSubmit";
import { loginUser } from '../functions/strapiGet';
import { StateContext } from '../data/stateContext';
import { useNavigate } from "react-router-dom";
import { Logout as Register } from '../components/Logout';
import { useSearchParams } from 'react-router-dom';
import { ErrorBox } from '../styled/ErrorBox';
import Logo from '../styled/Logo';
import LanguageSwitcher from '../components/LanguageSwitcher';


export default () => {

    const {saveJwt, user, setUser, allCopy, locale} = useContext(StateContext)
        // const {saveJwt,setUser} = useContext(StateContext)
    const [errorMessage, seterrorMessage] = useState();
    const navigate = useNavigate();

    const [credentials, setCredentials] = useState(
        {
            identifier:"",
            password:"",
        }
    );
    const [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
        // console.log( );
        if(searchParams.get("logout")) {
            saveJwt(null)
            setUser(null)
            navigate('/login')
        }
       
    }, []);




    useEffect(() => {
        // console.log(user);
        if(user) {
            navigate("/")
        }
    }, [user]);

    const handleChange = (e) => {
        console.dir(e.target.name);
        const updatedCredentials = {...credentials}

        updatedCredentials[e.target.name] = e.target.value;
        // console.log(updatedCredentials);
        setCredentials(updatedCredentials)
    }

    const handleSubmit = (e) => {
        e.preventDefault()

        loginUser(credentials).then(res => {
            if(res.jwt) {
                saveJwt(res.jwt)
                navigate("/")
            }
            if(res.error) {
                // console.log(res.error);
                seterrorMessage(<ErrorBox
                        message={res.error.message}
                        prompt={["Close"]}
                        cb={[()=>seterrorMessage(null)]}
                    />)

            }
        })
    }
    const handleClick = (e) => {
        navigate("/register")
    }
    const handleForgot = (e) => {
        const message = locale === "en" ? "If you have lost your password please click OK and follow the intructions" : "Hvis du har mistet din adganskode, klik på OK og følg instrukserne"
        seterrorMessage(<ErrorBox
            message={message}
            prompt={["Cancel","OK"]}
            cb={[()=>seterrorMessage(null),()=>navigate('/forgot-password')]}
        />)
    }
    return (
        <>
        <Logo />
        <TextBox>
            <div className='login'>{allCopy ? allCopy.login_text : null}</div>
            <LoginForm id="login" name="login" onSubmit={handleSubmit}>
                <label htmlFor="login">Login</label>
                {/* <input name="username" type="text" placeholder='Name'></input> */}
                <InputBox  onChange={handleChange} name="identifier" type="email" placeholder='Email' value={credentials.email}/>
                <InputBox  onChange={handleChange} name="password" type="password" placeholder={locale === "en" ? "Password" : "Adgangskode"} value={credentials.password}/>
                {/* <input name="repeatpassword" type="password" placeholder='Repeat Password'></input> */}
                <SubmitButton 
                    style={{margin:"2rem 0"}}
                    type="submit"
                    active={true}
                >
                    Login
                </SubmitButton>
            </LoginForm>
            <Register onClick={handleClick} >
                Register
            </Register>
            <Register style={{right:"3rem"}} onClick={handleForgot} >
                {locale === "en" ? "Forgot Password?" : "Glemt adgangskode?"}
            </Register>
        </TextBox>
            <LanguageSwitcher  pos={['top:10rem;','left:3rem;']} color={1} />
        {errorMessage}
        </>
    )
}